import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import detail from '../views/detail.vue'
import master from '../views/master.vue'
import store from "../store/index"
import xcxJump from "../views/xcx_jump.vue"
import * as Base64  from 'js-base64'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'center',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:Home
  },
  {
    path: '/xcx_jump',
    name: 'xcx_jump',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:xcxJump
  },
  {
    path: '/tcdetail_pr/:id',
    name: 'tcdetail_pr',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "tcdetail_pr" */ '../views/tcdetail_pr.vue')
  },
  {
    path: '/detail_pr/:id',
    name: 'detail_pr',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "detail_pr" */ '../views/detail_pr.vue')
  },
  {
    path: '/zyinfo',
    name: 'zyinfo',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "zyinfo" */ '../views/zyinfo.vue')
  },
  {
    path: '/zrinfo',
    name: 'zrinfo',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "zrinfo" */ '../views/zrinfo.vue')
  },
  {
    path: '/detail_cul/:id',
    name: 'detail_cul',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "detail_cul" */ '../views/detail_cul.vue')
  },
  {
    path: '/zr_cul',
    name: 'zr_cul',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "zr_cul" */ '../views/zr_cul.vue')
  },
  {
    path: '/zy_cul',
    name: 'zy_cul',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "zy_cul" */ '../views/zy_cul.vue')
  },
  
  {
    path: '/pt_login',
    name: 'pt_login',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "pt_login" */ '../views/pt_login.vue')
  },
  {
    path: '/home_index',
    name: 'home_index',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "home_index" */ '../views/home_index.vue')
  },
  {
    path: '/unit_info',
    name: 'unit_info',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "unit_info" */ '../views/unit_info.vue')
  },
  {
    path: '/list',
    name: 'list',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "list" */ '../views/list.vue')
  },
  {
    path: '/detail/:id',
    name: 'detail',
    meta:{
      title:'中国长城文创珍宝中心',
    },
    component:detail
  },
  {
    path: '/detail_unit/:id',
    name: 'detail_unit',
    meta:{
      title:'中国长城文创珍宝中心',
    },
    component:() => import(/* webpackChunkName: "detail_unit" */ '../views/detail_unit.vue')
  },
  {
    path:'/home',
    name:'master',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component: master
  },
  {
    path: '/grap/:id',
    name: 'grap',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "grap" */ '../views/grap.vue')
  },
  {
    path: '/record/:id',
    name: 'record',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "record" */ '../views/record.vue')
  },{
    path: '/index',
    name: 'index',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "index" */ '../views/index.vue')
  },
  {
    path: '/robot',
    name: 'robot',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "robot" */ '../views/robot.vue')
  },
  {
    path: '/preview',
    name: 'preview',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "preview" */ '../views/preview.vue')
  },
  {
    path: '/chongzhi',
    name: 'chongzhi',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "chongzhi" */ '../views/chongzhi.vue')
  },
  {
    path: '/ScoreRecord',
    name: 'ScoreRecord',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "ScoreRecord" */ '../views/ScoreRecord.vue')
  },
  {
    path: '/ConsumptionDetails',
    name: 'ConsumptionDetails',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "ConsumptionDetails" */ '../views/ConsumptionDetails.vue')
  },
  {
    path:'/guanli',
    name:'guanli',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "guanli" */ '../views/guanli.vue')
  },
  {
    path:'/masterinfo',
    name:'masterinfo',
    meta:{
      title:'中国长城文创珍宝中心',
      keepAlive:false
    },
    
    component:() => import(/* webpackChunkName: "masterinfo" */ '../views/masterinfo.vue')
  },
  {
    path:'/renzhen',
    name:'renzhen',
    meta:{
      title:'中国长城文创珍宝中心',
      keepAlive:false
    },
  
    component:() => import(/* webpackChunkName: "renzhen" */ '../views/renzhen.vue')
  },
  {
    path:'/shouquan',
    name:'shouquan',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "shouquan" */ '../views/shouquan.vue')
  },
  {
    path:'/sqDetail',
    name:'sqDetail',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "sqDetail" */ '../views/sqDetail.vue')
  },
  {
    path:'/xinwen',
    name:'xinwen',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "xinwen" */ '../views/xinwen.vue')
  },
  {
    path:'/banding',
    name:'banding',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "banding" */ '../views/banding.vue')
  },
  {
    path: '/zhuanyi/:id',
    name: 'zhuanyi',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "zhuanyi" */ '../views/zhuanyi.vue')
  },
  
  {
    path: '/zhenbao',
    name: 'zhenbao',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "zhenbao" */ '../views/zhenbao.vue')
  },
  {
    path: '/zbbox',
    name: 'zbbox',
    meta:{
      title:'中国长城文创珍宝中心',
    },
    component:() => import(/* webpackChunkName: "zbbox" */ '../views/zbbox.vue')
  },
  {
    path: '/sign',
    name: 'sign',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "sign" */ '../views/sign.vue')
  },
  
  {
    path: '/zhuanran',
    name: 'zhuanran',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "zhuanran" */ '../views/zhuanran.vue')
  },
  {
    path: '/zhuanran_cul',
    name: 'zhuanran_cul',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "zhuanran_cul" */ '../views/zhuanran_cul.vue')
  },
  
  // {
  //   path: '/qiugou',
  //   name: 'qiugou',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "qiugou" */ '../views/qiugou.vue')
  // },
  {
    path: '/collection',
    name: 'collection',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "collection" */ '../views/collection.vue')
  },
  {
    path: '/concern',
    name: 'concern',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "concern" */ '../views/concern.vue')
  },
 
  {
    path: '/data',
    name: 'data',
    meta:{
      title:'数据统计'
    },
    component:() => import(/* webpackChunkName: "data" */ '../views/data.vue')
  },
  {
    path: '/data_all',
    name: 'data_all',
    meta:{
      title:'数据统计'
    },
    component:() => import(/* webpackChunkName: "data_all" */ '../views/data_all.vue')
  },
  {
    path: '/buy_info/:id',
    name: 'buy_info',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "buy_info" */ '../views/buy_info.vue')
  },
  {
    path:'/home_col',
    name:'master_col',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component: () => import(/* webpackChunkName: "master_col" */ '../views_collect/master.vue')
  },
  {
    path:'/home_inst',
    name:'master_inst',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component: () => import(/* webpackChunkName: "master_inst" */ '../views_collect/master_inst.vue')
  },
  {
    path:'/guanli_col',
    name:'guanli_col',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "guanli_col" */ '../views_collect/guanli.vue')
  },
  {
    path:'/masterinfo_col',
    name:'masterinfo_col',
    meta:{
      title:'中国长城文创珍宝中心',
      keepAlive:true
    },
    
    component:() => import(/* webpackChunkName: "masterinfo_col" */ '../views_collect/masterinfo.vue')
  },
  {
    path:'/renzhen_col',
    name:'renzhen_col',
    meta:{
      title:'中国长城文创珍宝中心',
      keepAlive:false
    },
  
    component:() => import(/* webpackChunkName: "renzhen_col" */ '../views_collect/renzhen.vue')
  },
  {
    path:'/shouquan_col',
    name:'shouquan_col',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "shouquan_col" */ '../views_collect/shouquan.vue')
  },
  {
    path:'/sqDetail_col',
    name:'sqDetail_col',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "sqDetail_col" */ '../views_collect/sqDetail.vue')
  },
  {
    path:'/xinwen_col',
    name:'xinwen_col',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "xinwen_col" */ '../views_collect/xinwen.vue')
  },
  {
    path:'/banding_col',
    name:'banding_col',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "banding_col" */ '../views_collect/banding.vue')
  },
  {
    path:'/banding_inst',
    name:'banding_inst',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "banding_inst" */ '../views_collect/banding_inst.vue')
  },
  {
    path: '/zhuanyi_col/:id',
    name: 'zhuanyi_col',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "zhuanyi_col" */ '../views_collect/zhuanyi.vue')
  },
  {
    path: '/sign_col',
    name: 'sign_col',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "sign_col" */ '../views_collect/sign.vue')
  },
  {
    path: '/robot_col',
    name: 'robot_col',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "robot_col" */ '../views_collect/robot.vue')
  },
  {
    path: '/robot_inst',
    name: 'robot_inst',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "robot_inst" */ '../views_collect/robot_inst.vue')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "maintenance" */ '../views/maintenance.vue')
  },
  {
    path: '/talk',
    name: 'talk',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "talk" */ '../views/talk.vue')
  },
  {
    path: '/adtiViolation/:id',
    name: 'adtiViolation',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "adtiViolation" */ '../views/adtiViolation.vue')
  },
  {
    path: '/imgUpdate/:id',
    name: 'imgUpdate',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "imgUpdate" */ '../views/imgUpdate.vue')
  },
  {
    path: '/detailTeacher',
    name: 'detailTeacher',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "detailTeacher" */ '../views/detailTeacher.vue')
  },
  {
    path: '/aiyuyin/:id',
    name: 'aiyuyin',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "aiyuyin" */ '../views/aiyuyin.vue')
  },
  {
    path: '/tcdetail/:id',
    name: 'tcdetail',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "tcdetail" */ '../views/tcdetail.vue')
  },
  {
    path: '/report/:id',
    name: 'report',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "report" */ '../views/report.vue')
  },
  {
    path: '/repair',
    name: 'repair',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "repair" */ '../views/repair.vue')
  },
  // {
  //   path: '/digitalCollection',
  //   name: 'digitalCollection',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalCollection" */ '../views/data_coll.vue')
  // },
  // {
  //   path: '/applyDigital',
  //   name: 'applyDigital',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "applyDigital" */ '../views/apply_data.vue')
  // },
  // {
  //   path: '/applyingCollection/:id',
  //   name: 'applyingCollection',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "applyingCollection" */ '../views/coll_apply_detail.vue')
  // },
  // {
  //   path: '/digitalOrder/list',
  //   name: 'digitalOrder',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalOrder" */ '../views/digitalOrder.vue')
  // },
  // {
  //   path: '/digitalOrder/detail/:id',
  //   name: 'digitalOrderDetail',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalOrderDetail" */ '../views/digitalOrderDetail.vue')
  // },
  // {
  //   path: '/digitalSale',
  //   name: 'digitalSale',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalSale" */ '../views/digitalSale.vue')
  // },
  // {
  //   path: '/digitalSend/:id',
  //   name: 'digitalSend',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalSend" */ '../views/digitalSend.vue')
  // },
  // {
  //   path: '/digitalGet',
  //   name: 'digitalGet',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalGet" */ '../views/digitalGet.vue')
  // },
  // {
  //   path: '/digitalSendManger',
  //   name: 'digitalSendManger',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalSendManger" */ '../views/digitalSendManger.vue')
  // },
  // {
  //   path: '/digitalSendDetail/:id',
  //   name: 'digitalSendDetail',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalSendDetail" */ '../views/digitalSendDetail.vue')
  // },
  // {
  //   path: '/digitalCollection/:id',
  //   name: 'digitalCollections',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalCollections" */ '../views/digitalCollection.vue')
  // },
  // {
  //   path: '/userDigital',
  //   name: 'userDigital',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "userDigital" */ '../views/userDigital.vue')
  // },
  // {
  //   path: '/userDigitalSend/:id',
  //   name: 'userDigitalSend',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "userDigitalSend" */ '../views/userDigitalSend.vue')
  // },
  // {
  //   path: '/userDigitalGift/:id',
  //   name: 'userDigitalGift',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "userDigitalGift" */ '../views/userDigitalGift.vue')
  // },
  // {
  //   path: '/userDigitalDetail/:id',
  //   name: 'userDigitalDetail',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "userDigitalDetail" */ '../views/userDigitalDetail.vue')
  // },
  // {
  //   path: '/userDigitalSendDetail/:id',
  //   name: 'userDigitalSendDetail',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "userDigitalSendDetail" */ '../views/userDigitalSendDetail.vue')
  // },
  // {
  //   path: '/digitalList',
  //   name: 'digitalList',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "digitalList" */ '../views/digitalList.vue')
  // },
  // {
  //   path: '/userDigitalSendList',
  //   name: 'userDigitalSendList',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "userDigitalSendList" */ '../views/userDigitalSendList.vue')
  // },
  // {
  //   path: '/userDigitalGiftList',
  //   name: 'userDigitalGiftList',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "userDigitalGiftList" */ '../views/userDigitalGiftList.vue')
  // },
  // {
  //   path: '/applyGame',
  //   name: 'applyGame',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "applyGame" */ '../views/applyGame.vue')
  // },
  {
    path: '/institution',
    name: 'institution',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "institution" */ '../views_collect/institution.vue')
  },
  {
    path: '/apply3D',
    name: 'apply3D',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "apply3D" */ '../views/apply3d.vue')
  },
  // {
  //   path: '/gameJoin',
  //   name: 'gameJoin',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "gameJoin" */ '../views/gameJoin.vue')
  // },
  // {
  //   path: '/gamePass',
  //   name: 'gamePass',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "gamePass" */ '../views/gamePass.vue')
  // },
  // {
  //   path: '/gameAward',
  //   name: 'gameAward',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "gameAward" */ '../views/gameAward.vue')
  // },
  {
    path: '/privacy_info',
    name: 'privacy_info',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "privacy_info" */ '../views/privacy_info.vue')
  },
 
  // {
  //   path: '/phone',
  //   name: 'phone',
  //   meta:{
  //     title:'中国长城文创珍宝中心'
  //   },
  //   component:() => import(/* webpackChunkName: "phone" */ '../views/phone.vue')
  // },
  {
    path: '/unit',
    name: 'unit',
    meta:{
      title:'中国长城文创珍宝中心'
    },
    component:() => import(/* webpackChunkName: "unit" */ '../views/unit.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
  document.title = to.meta.title
  }
  
  
  let weui_mask = document.getElementsByClassName('weui-mask')
  
  if(weui_mask.length){
    weui_mask[0].parentElement.hide()
  }
  
  var u = navigator.userAgent, app = navigator.appVersion;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //如果输出结果是true就判定是android终端或者uc浏览器
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); 

    const to_fullPath = process.env.BASE_URL + to.fullPath.replace('/', '')
    const to_path = process.env.BASE_URL + to.path.replace('/', '')
    if (isiOS === true) {
      // weui.alert(to_path)
      
      if(!store.state.jssdkUrl){
        store.commit('updateJsUrl',to_fullPath)
      }
      next()
    }else{
      store.commit('updateJsUrl',to_fullPath)
      next()
    }
  
})


function deepCopy(obj) {  
  if (obj === null || typeof obj !== 'object') {  
    return obj;  
  }  
  
  let copy = obj.constructor();  
  
  for (let attr in obj) {  
    if (obj.hasOwnProperty(attr)) {  
      copy[attr] = deepCopy(obj[attr]);  
    }  
  }  
  
  return copy;  
}

function getQuery(str){
  let list = str.split('?')
  let queryjson = {}
  if(list.length > 1){
    let list1 = list[1].split('&')
    for(let i = 0; i < list1.length; i++){
      let item = list1[i].split('=')
      queryjson[item[0]] = item[1]
    }
    
  }
  return queryjson
}
function getParams(str){
  let list = str.split('/')
  let id = ''
  if(list.length > 2){
    if(list[2] != 'list'){
      id = list[2].split('?')[0]
    }
  }
  return id
}
function encryptBase64(param) {
  const encryptStr = Base64.encode(JSON.stringify(param)) || ''
  return encryptStr
}

const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  
  let newLocation = deepCopy(location)
  
  if(typeof newLocation == 'object'){
    if(newLocation.path.indexOf('/detail/') != -1 || newLocation.path.indexOf('/detail_unit/') != -1 || newLocation.path.indexOf('/grap/') != -1 || newLocation.path.indexOf('/record/') != -1 || newLocation.path.indexOf('/adtiViolation/') != -1 ||  newLocation.path.indexOf('/tcdetail/') != -1 || newLocation.path.indexOf('/report/') != -1 ){
      if(newLocation.query){
        newLocation = {
          path:newLocation.path,
          query:{
            __params: encryptBase64(newLocation.query)
          }
        }
      }
    }
    return originalPush.call(this, newLocation).catch(err => err)
  }
  if(newLocation.indexOf('/detail/') != -1 || newLocation.indexOf('/detail_unit/') != -1 || newLocation.indexOf('/grap/') != -1 || newLocation.indexOf('/record/') != -1 || newLocation.indexOf('/adtiViolation/') != -1 ||  newLocation.indexOf('/tcdetail/') != -1 || newLocation.indexOf('/report/') != -1 ){
    let query = getQuery(newLocation)
    let params = getParams(newLocation)
    if(params){
      newLocation = {
        path:newLocation.split('?')[0].replace(params,`id`),
        query:{
          __params: encryptBase64({...query,id:params})
        }
      }
    }
  }
  
  return originalPush.call(this, newLocation).catch(err => err)
}


export default router
