<template>
<div>
  <div class="wx-popup" v-show="flag" >
  <div class="content popup-container" v-show="flag">
    <div class="title">隐私保护指引</div>
    <div class="des">
       在使用当前公众号服务之前，请仔细阅读
       <span class="link" @click="goPriInfo" >{{privacyContractName}}</span>
       如你同意以上协议，请点击“同意”开始使用。
      </div>
      <div class="btns">
        <button class="item_pri reject" @click="exitMiniProgram"  >拒绝</button>
        <button class="item_pri agree" id="agree-btn1"  @click='showLogin' >同意</button>
      </div>
    </div>
</div>
<div class="mask" @click="hideLogin"  v-show="showPopup" ></div>

<div class="popup" v-show="showPopup">

  <div class="popup-header">
    <image class="logo" src="http://t.6a.com/95/011b96523e9de8bd.png?width=100"></image>
    <span>长城文创珍宝中心</span>
  </div>
 
  <div class="popup-text">请填写你的昵称、手机号码</div>
 
  <div class="popup-label">
    <span>昵称：</span>
    <input type="nickname" v-model="nickName"  placeholder="点击填写昵称" >
  </div>

  <div class="popup-label">
    <span>手机号码：</span>
    <input class="input" maxlength="11" v-model="phone" placeholder="点击填写手机号码" >
  </div>
 
  <div class="popup-button" @click="confirmLogin">提交</div >
  <div  class="popup-button1" @click="hideLogin">取消</div >
</div>
</div>

</template>

<script>
export default {
    props:{
        more:{
          type:String,
          default:''
        }
    },
    data(){
        return{
            nickName:'',
            phone:'',
            showPopup:false,
            flag:false,
            privacyContractName:'《长城文创珍宝中心公众号隐私保护指引》',
            is_phone:true
        }
    },
    methods:{
        hideLogin(){
            this.showPopup = false
            this.is_phone = false
        },
        openPopup(){
            //  this.showPopup = true
            if(this.$route.query.__params){
              this.more = this.more + '&__params=' + this.$route.query.__params
            }
            localStorage.setItem('login_before',this.$route.fullPath.split('?')[0])
            localStorage.setItem('login_more',this.more)
            let __params = ''
            if(this.$route.query.__params){
              __params = '?__params='+this.$route.query.__params 
            }
            history.replaceState(null,null,this.$route.fullPath.split('?')[0]+__params)
            this.$router.push('/phone')

        },
        exitMiniProgram(){
            this.flag = false
        },
        confirmLogin(){
          let phoneRegEx = /^1[3456789]\d{9}$/;
          if(this.phone.length == 0) {
            weui.alert('手机号不能为空')
            return
          }
          if (!phoneRegEx.test(this.phone)) {
            weui.alert('手机号有误')
            return 
          }
          if(this.$route.query.__params){
            this.more = this.more + '&__params=' + this.$route.query.__params
            
          }
            let url = location.protocol + "//" + location.host;
            
            window.location.href = "/api/auth/login?url=" +encodeURIComponent(url + this.$route.fullPath.split('?')[0]+`?phone=${this.phone}&phone_code=${this.nickName}`+this.more);
        },
        showLogin(){
          if(!this.$isWeiXin()){
            this.$router.push('/pt_login')
            return
          }
          if(!this.is_phone){
            localStorage.setItem('login_before',this.$route.fullPath.split('?')[0])
            localStorage.setItem('login_more',this.more)
            let __params = ''
            if(this.$route.query.__params){
              __params = '?__params='+this.$route.query.__params 
            }
            history.replaceState(null,null,this.$route.fullPath.split('?')[0]+__params)
            this.$router.push('/phone')
            this.flag = false
            // this.flag = false
            // this.showPopup = true
          }else{
            let url = location.protocol + "//" + location.host;
            let __params = ''
            let newmore = this.more ? this.more.replace('&','?') :''
            if(this.$route.query.__params){
              newmore = newmore ? newmore + '&__params='+this.$route.query.__params : '?__params='+this.$route.query.__params
            }
            window.location.href = "/api/auth/login?url=" + encodeURIComponent(url + this.$route.fullPath.split('?')[0]+ newmore);
          }
        },
        showFlag(){
          this.flag = true
        },
        goPriInfo(){
          this.$router.push('/privacy_info')
        }
    }
}
</script>

<style scoped>
/* component/popup.wxss */
.wx-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 1;
}
.popup-container {
  position: absolute;
  left: 50%;
  top: 50%;
  
  width: 80%;
  max-width: 3rem;
  border: 1px solid #ccc;
  border-radius: 0.05rem;
  box-sizing: bordre-box;
  transform: translate(-50%, -50%); 
  overflow: hidden;
  background: #fff;
}
.wx-popup-title {
  width: 100%;
  padding: 0.1rem;
  font-weight: bold;
}
.wx-popup-con {
  margin: 0.3rem 0.05rem;
  text-align: center;
}
.wx-popup-btn {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.2rem;
}
.wx-popup-btn span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 0.44rem;
  border: 1px solid #ccc;
  border-radius: 0.44rem;
}
.privacy {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 3.155rem;
  padding: 0.24rem;
  box-sizing: border-box;
  background: #fff;
  border-radius: 0.08rem;
  left: 50%;
  top: 50%;
}

.content .title {
  text-align: center;
  color: #333;
  font-weight: bold;
  font-size: 0.155rem;
  
}

.content .des {
  font-size: 0.13rem;
  color: #666;
  margin-top: 0.2rem;
  text-align: justify;
  line-height: 1.6;
}

.content .des .link {
  color: #07c160;
  text-decoration: underline;
}

.btns {
  margin-top: 0.24rem;
  display: flex;
  justify-content: space-between;
}

.btns .item_pri {
  
  width: 1.22rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.08rem;
  box-sizing: border-box;
  border: none;
}

.btns .reject {
  background: #f4f4f5;
  color: #909399;
  width: 1rem;
}

.btns .agree {
  background: #07c160;
  color: #fff;
  width: 1rem;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

/* 弹窗样式 */
.popup {
  position: fixed;
  bottom: 0;
  left: 2%;
  width: 96%;
  height: 53%; /* 占据页面的1/2 */
  background-color: #fff;
  padding: 0.225rem; /* 内间距 */
  border-radius: 0.1rem 0.1rem 0 0;
  box-sizing: border-box;
  z-index: 10;
}

/* 弹窗标题样式 */
.popup-header {
  display: flex;
  align-items: center;
  padding-top: 0.05rem;
}

/* 圆形 logo 样式 */
.logo {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  margin-right: 0.1rem;
}

/* 提示文本样式 */
.popup-text {
  padding: 0.175rem 0;
  font-weight: bold;
}

/* 标签样式 */
.popup-label {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(231, 229, 229);

  height: 0.6rem;
}

/* 箭头样式 */


/* 输入框样式 */
.popup-label input {
  flex: 1;
  outline: none;
  padding: 0.1rem;
  border: none;
}

/* 按钮样式 */
.popup-button {
  width: 100%;
  background-color: #E8F1FE;
  color: #3e6ece;
  text-align: center;
  padding: 0.1rem 0;
  border: none;
  outline: none;
  border-radius: 0.075rem;
  margin-top: 0.25rem;
}
.popup-button1 {
  width: 100%;
  background-color: #fff;
  color: #5C5C5C;
  text-align: center;
  padding: 0.1rem 0;
  border: none;
  outline: none;
}
</style>