<template>
    <div class="pop" @click="changeShow" v-show="is_show" v-if="list.length">
        <div class="pop_in" >
        <div class="flex" style="margin-bottom:0.1rem;padding-left:0.1rem" @click.stop>
            <img class="icon_type" :src="(list[actIndex1].pl_pic).replace(/((^[A-Za-z]{4,5})+\:)/,protocol)"/>
            <div class="title">{{list[actIndex1].name}}</div>
        </div>
        <div class="flex" @click.stop >
            <div :class="['before',actIndex1 != 0 ? 'has_before': 'no_before']" @click="to_before"></div>
            <div class="img_c" @touchstart="tstart" @touchmove="tmove" @touchend="tend" :style="`transform: translateX(${py}px); ${py==0?'transition:0.1s':''}` ">
                <van-image class="img_c" :src="((list[actIndex1].pic+'?width=750').replace(/((^[A-Za-z]{4,5})+\:)/,protocol)).replace('&#44;',',')" alt="" >
                    <template v-slot:loading>
                        <van-loading type="spinner" size="48" />
                    </template>
                </van-image>
            </div>
            <div :class="['next',actIndex1 == list.length - 1 ? 'no_next': 'has_next']" @click="to_next"></div>
        </div>
        <div class="copy_link" @click.stop="copy">{{$t('main.copyLink')}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name:'share',
    props:{
        list:{
            type:Array,
            default:[
            ]
        },
        actIndex:{
            type:Number,
            default:0
        },
        
    },
    data(){
        return{
            type:'',
            is_show:false,
            protocol:'http',
            now_y:0,
            is_start:false,
            is_right:false,
            py:0,
            actIndex1:0
        }
    },
    watch:{
        actIndex(){
            this.actIndex1 = this.actIndex
        }
    },
    methods:{
        changeShow(){
            this.is_show = !this.is_show
        },
        to_next(){
            if(this.actIndex1 == this.list.length-1){
                return
            }
            this.actIndex1++
        },
        to_before(){
            if(this.actIndex1 == 0){
                return
            }
            this.actIndex1--
        },
        copy(){
            
            let save = (e) => {
                e.clipboardData.setData("text/plain", this.list[this.actIndex1].href);
                e.preventDefault(); //阻止默认行为
            };
            document.addEventListener("copy", save);
            document.execCommand("copy");
            weui.toast(this.$t('main.copySucc'));
        },
        tstart(e){
            this.now_y = e.touches[0].screenX
            this.is_start = true
            
        },
        tmove(e){
            if(this.is_start){
                let betwe = e.touches[0].screenX - this.now_y
                this.py = betwe
                this.is_right =  (betwe) < 0
            }
        },
        tend(){
            if(Math.abs(this.py) > 30){
                this.py = !this.py
                if(this.is_right){
                    this.to_next()
                }else{
                    this.to_before()
                }
            }
            this.py = 0
            this.is_start = false
        }
    },
    mounted(){
        this.protocol = location.protocol
    }
}
</script>

<style scoped>
.pop{
		position: fixed;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.6) !important;
		top: 0;
		left: 0;
}
.before{
    position: absolute;
    left: 0;
    z-index: 1;
}
.next{
    position: absolute;
    right: 0;
    z-index: 1;
}
.pop_in{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.has_next{
    background: url(../assets/img/next_page.png) no-repeat;
    width: 0.325rem;
    height: 0.325rem;
    background-size: 100% 100%;
}
.no_next{
    background: url(../assets/img/next_page_white.png) no-repeat;
    width: 0.325rem;
    height: 0.325rem;
    background-size: 100% 100%;
    
}
.no_before{
    background: url(../assets/img/before_page_white.png) no-repeat;
    width: 0.325rem;
    height: 0.325rem;
    background-size: 100% 100%;
}
.has_before{
    background: url(../assets/img/before_page.png) no-repeat;
    width: 0.325rem;
    height: 0.325rem;
    background-size: 100% 100%;
}
.icon_type{
    width: 0.36rem;
    height: 0.36rem;
    margin-right: 0.1rem;
}
.flex{
    display: flex;
    align-items: center;
}
.title{
    font-size: 0.15rem;
    color: #fff;
}
.img_c{
    position: relative;
    width: 100vw;
}
.img_c img{
    width: 100%;
    object-fit: contain;
}
.copy_link{
    
    width: 1.13rem;
  height: 0.36rem;
  margin: 0 auto;
  margin-top: 0.22rem;
  background: url(../assets/img/my-zb-btn.png) no-repeat;
  background-size: 1rem 0.32rem;
  background-position: center center;
  background-color: #9a2a25;
  font-size: 0.14rem;
  line-height: 0.14rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.05rem;
}
</style>