import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from './lang'
import * as Base64  from 'js-base64'
import qs from 'qs';
// import "@/assets/font/fonts.css";

import Vant from 'vant';
import VueI18n from 'vue-i18n'
import 'vant/lib/index.css';
import { Lazyload } from 'vant';

Vue.use(Vant);
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(VueI18n)
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
const apis = {
  production: '/api', // 线上 (生成环境)
  development: '/api', // 本地 (开发环境)
}
// Vue.use(Viewer)


axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? apis.production : apis.development

Vue.config.productionTip = false
Vue.prototype.axios = axios
Vue.prototype.$base64 = Base64

function isWeiXin(){
  let userAgent = navigator.userAgent.toLowerCase()
  if(userAgent.indexOf('micromessenger') !== -1){
    return true
  }else{
    return false
  }
}

Vue.prototype.$isWeiXin = isWeiXin

axios.interceptors.request.use(config => {
  if(config.url == "/common/uploadImg"){

  }else{
    
    if(config.url == "common/encrypt"||config.url == 'auth/getPhoneCode' ||config.method == 'get'){
      config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      config.data = qs.stringify(config.data)
    }
    if(config.data){
      if(config.data.data){
        config.headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
        config.data = qs.stringify(config.data)
      }
    }
  }
  
  config.headers.Authorization = 'bearer ' + localStorage.getItem('user_token')
  return config
}, error => {

})

axios.interceptors.response.use((res) => {

  if (res.data.code && res.data.code == -100) {
    localStorage.setItem('maintenance', res.data.msg)
    window.location.replace('/maintenance')
  }

  if (res.data.code && res.data.code == 401) {
    localStorage.removeItem('user_token')
    if(!isWeiXin()){
      window.location.href = '/pt_login'
      return
    }
    if (router.app._route.query && router.app._route.query.artistType == 2) {

      if(localStorage.getItem('is_inst')){
        window.location.href = '/home_inst'
      }else{
        window.location.href = '/home_col'
      }

      
    }else if(router.app._route.fullPath.indexOf('/applyGame') != -1||router.app._route.fullPath.indexOf('Game') != -1){
      window.location.href = '/api/auth/login?url=' +encodeURIComponent(location.protocol + '//' + location.host + router.app._route.fullPath) 
    } else if (router.app._route.fullPath.indexOf('detail') != -1 || router.app._route.fullPath.indexOf('imgUpdate') != -1 || router.app._route.fullPath.indexOf('adtiViolation') != -1 || router.app._route.fullPath.indexOf('aiyuyin') != -1 || router.app._route.fullPath.indexOf('report') != -1 || router.app._route.fullPath.indexOf('digitalCollection/') != -1) {
      window.location.href = '/api/auth/login?url=' + encodeURIComponent(location.protocol + '//' + location.host + router.app._route.fullPath)
    } else if (res.config.url.indexOf('/collection/mine') != -1 || res.config.url.indexOf('/collection/invite') != -1 || res.config.url.indexOf('collection/getMyApplyBuy') != -1 || router.app._route.fullPath.indexOf('userDigital') != -1) {
      window.location.href = '/api/auth/login?url=' + location.protocol + '//' + location.host + '/zbbox'
    } else {
      if (router.app._route.fullPath.indexOf('_col') != -1) {
        if(localStorage.getItem('is_inst')){
          window.location.href = '/home_inst'
        }else{
          window.location.href = '/home_col'
        }
      } else if(router.app._route.fullPath.indexOf('_inst') != -1){
        window.location.href = '/home_inst'
      } else{
        window.location.href = '/home'
      }

    }


  }

  return res
}, (err) => {
  if (err.response.status == 429) {
    weui.alert(this.$t('main.ofptal'), { buttons: [{ label: this.$t('main.confIrm') }] })
  }
}
)


if (localStorage.getItem('lang')) {
  i18n.locale = localStorage.getItem('lang')
} else {
  var lang = navigator.language
  if (lang == "en-US" || lang == 'en') {
    i18n.locale = 'en'
  }
}





var vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
global.vm = vm;
