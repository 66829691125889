<template>
  <div class="out">
    <div class="header">
      <div class=""></div>

      <div class="right-icon">
        <img src="../assets/img/logo-icon.png" alt="" />
      </div>
      <div class=""></div>
    </div>
    <div class="bigTitle">
      <img src="../assets/img/bigtitle.png" alt="" srcset="" />
    </div>
    <div class="smallTitle">
      <img src="../assets/img/smalltitle1.png" alt="" />
    </div>
    <div class="bg_mast" style="display: flex; justify-content: space-between"
      v-show="this.can_more_help && this.master_list.length">
      <div>{{$t('main.nm')}}：{{ ass_name }}</div>
      <div @click="changeMaster">{{$t('main.swma')}}</div>
    </div>
    <div class="bg_mast" v-show="((this.isMaster && this.isMaster != '0') || this.isAcc) && mast_name&&!this.can_more_help">
      <div>{{$t('main.welcome')}}，{{isAcc ? $t('main.nm')+'：'+mast_name : $t('main.xMaster',{X:mast_name})}}</div>
      <img src="../assets/img/QRcode-logo.png" alt="" class="icon-tips" @click="actionS()" />
      <!-- <div
        class="icon-tips"
        @click="actionS(itemId)"
      ></div> -->
    </div>
     <div class="to_be_master" @click="show_pop" v-show="(!this.isMaster || this.isMaster == '0') && !this.isAcc">
      <img src="../assets/img/to_be_master.png" alt="" />
    </div>
    <div class="content">
      <div class="modular-box">
        <div class="modular-item" @click="goPage(1)">
          <span>{{$t('main.celebrityAuth')}}<br />{{$t('main.infoedit')}}</span>
        </div>
        <div class="modular-item" @click="goPage(2)">
          {{$t('main.tpc')}}
        </div>
        <div class="modular-item" @click="goPage(3)">
          {{$t('main.new')}}
        </div>
        <div class="modular-item" @click="goPage(4)">
          {{$t('main.tM')}}
        </div>
        <div class="modular-item" @click="goPage(5)" v-show="!isAcc">
          {{$t('main.accauth')}}
        </div>
        <div class="modular-item" @click="goPage(6)" v-show="!isAcc">
          {{$t('main.signSet')}}
        </div>
        <div class="modular-item" @click="goPage(7)">
          {{$t('main.Aiaudio')}}
        </div>
        <div class="modular-item" @click="goPage(9)">
          3D珍宝
        </div>
        <!-- <div class="modular-item" @click="goPage(8)">
          数字藏品
        </div> -->
      </div>
    </div>

    <!-- <div class="ywy"  v-show="bind_salesman_id" >
			<div class="top border"></div>
			<div class="bottom border"></div>
			<div class="left border"></div>
			<div class="right border"></div>
			{{$t('main.welcome')}}，{{bind_salesman_name}}
		</div> -->
    <div class="ywy1" v-show="bind_salesman_id">
      <span> {{$t('main.welcome')}}，{{ bind_salesman_name }} </span>
      <div class="ywytj" @click="goData">
        {{$t('main.statistics')}}
      </div>
    </div>
    <div style="display: flex; justify-content: flex-end; padding-right: 0.08rem" v-if="is_auth">
      <div class="ywytj" @click="goData(1)">
        {{$t('main.at')}}
      </div>
    </div>
   

    <div class="pop pop_mask" v-show="shouquan" @click="shouquan = false"></div>
    <div class="pop pop-cy-rz" v-show="qrcode1" @click="closeQr1">
      <div class="pop-qrcode">
        <div>
          <img :src="nowZS" alt="" />
        </div>
        <div class="pop-tishi"><img src="../assets/img/click-logo-zm.png" alt=""> {{$t('main.lodqr')}}</div>
      </div>
    </div>
    <changelang></changelang>
    <privacy ref="privacy"></privacy>
    <notice v-if="is_loaded"></notice>
    <img src="../assets/img/border_qrcode.png" id="border" style="display:none" alt="">
    <img src="../assets/img/qrcode_name_3.png" id="name" style="display:none" alt="">
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import changelang from "../components/changelang.vue"
import privacy from "../components/privacy.vue"
import notice from "../components/notice.vue"
export default {
  components:{
    changelang,
    privacy,
    notice
  },
  data() {
    return {
      msg: 123,
      code: "",
      popText: "",
      dsFlag: false,
      token: "",
      isMaster: false,
      isAcc: false,
      bind_salesman_id: null,
      bind_salesman_name: "",
      status: 0,
      loading: null,
      qrcode: false,
      artist_id: null,
      shouquan: false,
      mast_name: "",
      is_auth: false,
      can_more_help: false,
      master_list: [],
      ass_name: "",
      protocol: "",
      itemId: "",
      qrcode1: false,
      nowZS: "",
      birthday: "",
      gender: "",
      infoStatus: false,
      isArtist: "",
      compete_status:0,
      is_loaded:false
    };
  },

  created() { },
  mounted() {
    this.protocol = location.protocol;

    if (this.getQueryString("code") || localStorage.getItem("user_token")) {
      this.code = this.getQueryString("code");
      if (localStorage.getItem("user_token")) {
        this.loading = weui.loading(this.$t('main.loading'));
        this.axios.get("/auth/me").then((res) => {
          this.loading.hide();
          this.is_loaded = true
          this.isAcc = res.data.data.user.artist_user_id;
          if (this.isAcc) {
            localStorage.setItem("isAcc", "true");
          } else {
            localStorage.setItem("isAcc", "false");
          }

          this.bind_salesman_id = res.data.data.user.bind_salesman_id;
          this.isArtist = res.data.data.user.is_artist;
          this.compete_status = res.data.data.user.compete_status
          if (res.data.data.user.artist) {
            this.itemId = res.data.data.user.artist.id
            this.status = res.data.data.user.artist.status || 0;
            this.birthday = res.data.data.user.artist.birthday ? res.data.data.user.artist.birthday : '';
            if (typeof res.data.data.user.artist.gender == "number") {
              res.data.data.user.artist.gender = res.data.data.user.artist.gender + '';
            }
            this.gender = res.data.data.user.artist.gender ? res.data.data.user.artist.gender : '';
            this.infoStatus = res.data.data.user.infoStatus ? res.data.data.user.infoStatus : false;
            this.mast_name = res.data.data.user.artist.name;
            if (this.status == 4) {
              this.status = -1
            }
            this.itemId = res.data.data.user.artist.id;
          } else {
            this.status = -1;
          }
          this.tanchuang()
          if (this.status != -1 && !this.isAcc) {
            if (
              !res.data.data.user.artist.signs ||
              !res.data.data.user.artist.signs.sign
            )
              weui.alert(this.$t('main.nosetsse'), {
                buttons: [
                  { label: this.$t('main.cancel'), onClick: () => { } },
                  {
                    label: this.$t('main.confIrm'),
                    onClick: () => {
                      this.$router.push("/sign");
                    },
                  },
                ],
              });
          }
          if (
            this.status != -1 &&
            !res.data.data.user.artist.sign_password &&
            !this.isAcc
          ) {
            weui.alert(this.$t('main.nosetpwnse'), {
              buttons: [
                { label: this.$t('main.cancel'), onClick: () => { } },
                {
                  label: this.$t('main.confIrm'),
                  onClick: () => {
                    this.$router.push("/sign");
                  },
                },
              ],
            });
          }
          this.artist_id = res.data.data.user.artist_id;

          this.isMaster = res.data.data.user.is_artist;
          this.compete_status = res.data.data.user.compete_status
          if (this.bind_salesman_id) {
            this.is_auth = res.data.data.user.bindsalesman.auth == 1;
            this.bind_salesman_name = res.data.data.user.bindsalesman.name;
            let sa_info = {
              bank_account: res.data.data.user.bindsalesman.bank_account,
              bank_duty_number:
                res.data.data.user.bindsalesman.bank_duty_number,
              bank_name: res.data.data.user.bindsalesman.bank_name,
              company_name: res.data.data.user.bindsalesman.company_name,
            };
            localStorage.setItem("salesman_info", JSON.stringify(sa_info));
          }

          localStorage.setItem("art_id", res.data.data.user.artist_id);
          if (res.data.data.user.can_more_help != 0) {
            this.can_more_help = true;
            this.axios.post("auth/getArtistList", { type: 1 }).then((res) => {
              this.master_list = res.data.data.list;
              let art_id = this.artist_id;
              let artist = this.master_list.find((item) => {
                return item.artist_id == art_id;
              });
              this.ass_name = artist.artist
                ? artist.artist.name
                : artist.artist_user.username;
            });
          }
        });
        return;
      }

      if (this.code) {
        this.loadData();
      }

      // if(!window.localStorage.getItem('sq_info')){
      // 	window.localStorage.setItem('sq_info',this.code)
      // 	this.loadData()
      // }
    } else {
      // let url = location.protocol + "//" + location.host;
      
      // if (process.env.NODE_ENV === "production") {
      //   window.location.href = "/api/auth/login?url=" + url + "/home";
      // } else {
       
      //   window.location.href = '/api/auth/login?url=' + url + '/home'
      // }
      this.$refs.privacy.showFlag()
    }
  },
  methods: {
    tanchuang() {
      if (!this.isArtist || this.isArtist == "0") {
        console.log(this.isArtist)
        return;
      }
      if (!this.birthday && !this.infoStatus || !this.gender && !this.infoStatus) {
        
        weui.alert(this.$t('main.ains'), {
          buttons: [
            { label: this.$t('main.cancel'), onClick: () => { } },
            {
              label: this.$t('main.confIrm'),
              onClick: () => {
                this.$router.push({ path: "/masterinfo" });
              },
            },
          ],
        });
      }
    },
    loadData() {
      this.loading = weui.loading(this.$t('main.loading'));
      let querys = ''
      if(this.$route.query.phone){
        let phone =this.getQueryString('phone')
        let nickName = this.getQueryString('phone_code')
        console.log(nickName)
        querys = `&phone=${phone}&phone_code=${nickName}`
        console.log(querys)
      }
      this.axios
        .get("/auth/nt?code=" + this.code + querys)
        .then((res) => {
          this.loading.hide();
          console.log(res)
          if(res.data.code == -1 && res.data.msg == '请填写手机号'){
            
            this.$refs.privacy.openPopup()
            return
          }
          if (res.data.msg == this.$t('main.notheauth')) {
            this.isMaster = false;
            localStorage.setItem("isMaster", false);
          } else if (res.data.msg == "success") {
            this.is_loaded = true
            this.axios.get("/auth/me").then((res) => {
              this.isAcc = res.data.data.user.artist_user_id;
              if (this.isAcc) {
                localStorage.setItem("isAcc", "true");
              } else {
                localStorage.setItem("isAcc", "false");
              }
              this.isMaster = res.data.data.user.is_artist;
              this.isArtist = res.data.data.user.is_artist;
              this.compete_status = res.data.data.user.compete_status
              if (res.data.data.user.artist) {

                this.itemId = res.data.data.user.artist.id
                this.status = res.data.data.user.artist.status || 0;
                this.birthday = res.data.data.user.artist.birthday ? res.data.data.user.artist.birthday : '';
                if (typeof res.data.data.user.artist.gender == "number") {
                  res.data.data.user.artist.gender = res.data.data.user.artist.gender + '';
                }
                this.gender = res.data.data.user.artist.gender ? res.data.data.user.artist.gender : '';
                this.infoStatus = res.data.data.user.infoStatus ? res.data.data.user.infoStatus : false;
                this.mast_name = res.data.data.user.artist.name;
                if (this.status == 4) {
                  this.status = -1
                }
                this.itemId = res.data.data.user.artist.id;
              } else {
                this.status = -1;
              }
              this.tanchuang()
              if (this.status != -1 && !this.isAcc) {
                if (
                  !res.data.data.user.artist.signs ||
                  !res.data.data.user.artist.signs.sign
                )
                  weui.alert(this.$t('main.nosetsse'), {
                    buttons: [
                      { label: this.$t('main.cancel'), onClick: () => { } },
                      {
                        label: this.$t('main.confIrm'),
                        onClick: () => {
                          this.$router.push("/sign");
                        },
                      },
                    ],
                  });
              }
              if (
                this.status != -1 &&
                !res.data.data.user.artist.sign_password &&
                !this.isAcc
              ) {
                weui.alert(this.$t('main.nosetpwnse'), {
                  buttons: [
                    { label: this.$t('main.cancel'), onClick: () => { } },
                    {
                      label: this.$t('main.confIrm'),
                      onClick: () => {
                        this.$router.push("/sign");
                      },
                    },
                  ],
                });
              }

              this.artist_id = res.data.data.user.artist_id;
              localStorage.setItem("art_id", res.data.data.user.artist_id);
              this.bind_salesman_id = res.data.data.user.bind_salesman_id;
              if (this.bind_salesman_id) {
                this.bind_salesman_name = res.data.data.user.bindsalesman.name;
                this.is_auth = res.data.data.user.bindsalesman.auth == 1;
                let sa_info = {
                  bank_account: res.data.data.user.bindsalesman.bank_account,
                  bank_duty_number:
                    res.data.data.user.bindsalesman.bank_duty_number,
                  bank_name: res.data.data.user.bindsalesman.bank_name,
                  company_name: res.data.data.user.bindsalesman.company_name,
                };
                localStorage.setItem("salesman_info", JSON.stringify(sa_info));
              }
              if (res.data.data.user.can_more_help != 0) {
                this.can_more_help = true;
                this.axios
                  .post("auth/getArtistList", { type: 1 })
                  .then((res) => {
                    this.master_list = res.data.data.list;
                    let art_id = this.artist_id;
                    let artist = this.master_list.find((item) => {
                      return item.artist_id == art_id;
                    });
                    this.ass_name = artist.artist
                      ? artist.artist.name
                      : artist.artist_user.username;
                  });
              }
            });
            if (res.data.msg == "请授权登录") {
              this.shouquan = true;
            }
            localStorage.setItem("isMaster", true);
            window.localStorage.setItem("user_token", res.data.data.token);
          }
        })
        .catch((err) => {
          window.localStorage.removeItem("sq_info");

          // return
          let url = location.protocol + "//" + location.host;
          if (process.env.NODE_ENV === "production") {
            window.location.href = "/api/auth/login?url=" + url + "/home";
          } else {
            window.location.href = "/api/auth/login?url=" + url + "/home";
          }
        });
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return null;
    },
    goPage(val) {
      if(!localStorage.getItem('user_token')){
        this.$refs.privacy.showFlag()
				return
			}
      if(!this.$isWeiXin()){
        weui.alert('请在微信浏览器打开')
        return
      }
      if ((!this.isMaster || this.isMaster == "0") && !this.isAcc) {
        weui.alert(this.$t('main.soryyna'),{buttons:[{label:this.$t('main.confIrm')}]});
        return;
      } else if (
        (this.status == -1 || !this.artist_id) &&
        val != 1 &&
        val != 5
      ) {
        weui.alert(this.$t('main.pcaieditf'),{buttons:[{label:this.$t('main.confIrm')}]});
        return;
      }
      let times = new Date().getTime();
      switch (val) {
        case 1:
          this.$router.push({ path: "/masterinfo?time=" + times });
          break;
        case 2:
          if(this.compete_status == 2){
            weui.actionSheet([
                {
                  label: '发布文创大赛作品',
                  onClick: () => {
                      this.$router.push({ path: "/renzhen?time=" + times });
                  },
                },
                {
                  label:'发布普通作品',
                  onClick: () => {
                      this.$router.push({ path: "/renzhen?time=" + times });
                  },
                }
            ])
          }else{
             this.$router.push({ path: "/renzhen?time=" + times });
          }
          //  this.$router.push({ path: "/renzhen?time=" + times });
          break;
        case 3:
          this.$router.push({ path: "/xinwen?teim=" + times });
          break;
        case 4:
          this.$router.push("/guanli");
          break;
        case 5:
          this.$router.push("/shouquan");
          break;
        case 6:
          this.$router.push("/sign");
          break;
        case 7:
          this.$router.push({
            path: "/aiyuyin/" + this.artist_id,
            query: {
              artistType: 1
            }
          });
          break;
        case 8:
          this.$router.push({
            path: "/digitalCollection",
            query: {
              artistType: 1
            }
          });
          break;
        case 9:
          this.$router.push({
            path: "/apply3D",
            query: {
              artistType: 1
            }
          });
          break;
        default:
          break;
      }
    },
    show_pop() {
      if(!localStorage.getItem('user_token')){
        this.$refs.privacy.showFlag()
				return
			}
      this.$router.push("/robot");
    },
    goData(index = 0) {
      if (index == 1) {
        this.$router.push("/data_all");
        return;
      }
      this.$router.push("/data");
    },
    actionS() {
      let url = location.protocol + "//" + location.host; //http://192.168.1.81:8080
      let div = document.createElement("div");
      let code = new QRCode(div, {
        text: url + "/tcdetail/" + this.artist_id + "?status=1", //要生成二维码的网址
        width: 410, //图像宽度
        height: 410, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        margin: 20, //外边距
        correctLevel: QRCode.CorrectLevel.L, //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
      }); // div canvas
      let canvas = code._el.querySelector("canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
      let qr_ctx = canvas.getContext('2d')
      let qr_img = qr_ctx.getImageData(0, 0, canvas.width, canvas.height) //获取二维码图片数据

      var canvas1 = document.createElement('canvas')
      var ctx = canvas1.getContext('2d')
      var border = document.getElementById('border')

      canvas1.width = border.width
      canvas1.height = border.height
      ctx.drawImage(border, 0, 0, canvas1.width, canvas1.height)
      var name = document.getElementById('name')

      ctx.font = "25px Arial";
      ctx.fillStyle = '#fcf6dc'
      ctx.textAlign = "center"
      if (ctx.measureText(this.mast_name).width < (name.width - 50)) {
        ctx.drawImage(name, 290, 35, name.width, name.height)

        ctx.fillText(this.mast_name, 290 + (name.width / 2), 67)//姓名
      } else {
        ctx.drawImage(name, 240, 35, 200, 50)

        ctx.fillText(this.mast_name, 340, 67)
      }
      ctx.fillStyle = '#FFFFFF'
      ctx.fillRect(30, 100, 430, 430)
      ctx.putImageData(qr_img, 40, 110)
      this.nowZS = canvas1.toDataURL("image/png");

      this.qrcode1 = true;
    },

    closeQr1() {
      this.qrcode1 = false;
    },
    changeMaster() {
      let pick = [];
      for (let i = 0; i < this.master_list.length; i++) {
        pick.push({
          label: this.master_list[i].artist
            ? this.master_list[i].artist.name
            : this.master_list[i].artist_user.username,
          value: i,
        });
      }
      weui.picker(pick, {
        onConfirm: (res) => {
          let art = this.master_list[res[0].value];
          this.axios
            .post("auth/chooseArtist", {
              type: 1,
              artist_user_id: art.artist_user.id,
              artist_id: art.artist.id,
            })
            .then((res) => {
              if (res.data.code == 0) {
                weui.toast(this.$t('main.switchSucc'));
                this.axios.get("/auth/me").then((res) => {
                  this.isAcc = res.data.data.user.artist_user_id;
                  this.isMaster = res.data.data.user.is_artist;
                  this.isArtist = res.data.data.user.is_artist;
                  if (res.data.data.user.artist) {
                    this.status = res.data.data.user.artist.status || 0;
                    this.mast_name = res.data.data.user.artist.name;
                    this.birthday = res.data.data.user.artist.birthday ? res.data.data.user.artist.birthday : '';
                    if (typeof res.data.data.user.artist.gender == "number") {
                      res.data.data.user.artist.gender = res.data.data.user.artist.gender + '';
                    }
                    this.gender = res.data.data.user.artist.gender ? res.data.data.user.artist.gender : '';
                    this.infoStatus = res.data.data.user.infoStatus ? res.data.data.user.infoStatus : false;
                    if (this.status == 4) {
                      this.status = -1
                    }
                  } else {
                    this.status = -1;
                  }
                  this.tanchuang()
                  if (this.status != -1 && !this.isAcc) {
                    if (
                      !res.data.data.user.artist.signs ||
                      !res.data.data.user.artist.signs.sign
                    )
                      weui.alert(this.$t('main.nosetsse'), {
                        buttons: [
                          { label: this.$t('main.cancel'), onClick: () => { } },
                          {
                            label: this.$t('main.confIrm'),
                            onClick: () => {
                              this.$router.push("/sign");
                            },
                          },
                        ],
                      });
                  }
                  if (
                    this.status != -1 &&
                    !res.data.data.user.artist.sign_password &&
                    !this.isAcc
                  ) {
                    weui.alert(this.$t('main.nosetpwnse'), {
                      buttons: [
                        { label: this.$t('main.cancel'), onClick: () => { } },
                        {
                          label: this.$t('main.confIrm'),
                          onClick: () => {
                            this.$router.push("/sign");
                          },
                        },
                      ],
                    });
                  }

                  this.artist_id = res.data.data.user.artist_id;
                  localStorage.setItem("art_id", res.data.data.user.artist_id);
                  this.bind_salesman_id = res.data.data.user.bind_salesman_id;
                  if (this.bind_salesman_id) {
                    this.bind_salesman_name =
                      res.data.data.user.bindsalesman.name;
                    this.is_auth = res.data.data.user.bindsalesman.auth == 1;
                    let sa_info = {
                      bank_account:
                        res.data.data.user.bindsalesman.bank_account,
                      bank_duty_number:
                        res.data.data.user.bindsalesman.bank_duty_number,
                      bank_name: res.data.data.user.bindsalesman.bank_name,
                      company_name:
                        res.data.data.user.bindsalesman.company_name,
                    };
                    localStorage.setItem(
                      "salesman_info",
                      JSON.stringify(sa_info)
                    );
                  }

                  let art_id = this.artist_id;
                  let artist = this.master_list.find((item) => {
                    return item.artist_id == art_id;
                  });
                  this.ass_name = artist.artist
                    ? artist.artist.name
                    : artist.artist_user.username;
                });
              }
            });
        },
      });
    },
  },
};
</script>

<style scoped>
.out {
  padding: 0.21rem 0.15rem;
  min-height: 100vh;
  box-sizing: border-box;
  background: url(../assets/img/home_bg.png) no-repeat center;
  background-size: cover;
  /* filter:grayscale(100%); */
}

.pop {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.pop_mask {
  background: url(../assets/img/kz_pop.png) no-repeat;
  background-size: 100% 100%;
}

.pop .pop-in {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3.45rem;
  box-sizing: border-box;
  padding: 0.15rem;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 0.08rem;
  font-size: 0.15rem;
}

.header {
  display: flex;
  justify-content: space-between;
  /* line-height: 0; */
}

.content {
  margin-top: 0.225rem;
}

.Title {
  font-size: 0.15rem;
  position: relative;
  margin-top: -0.03rem;
}

.cc-wc {
  color: #eec194;
  font-size: 0.15rem;
}

.black_text {
  font-size: 0.12rem;
  margin-bottom: 0.15rem;
}

.English {
  color: #754c4f;
  position: absolute;
  top: 0.05rem;
  left: 0.1rem;
}

.Chinese {
  color: #ffffff;
  left: 0;
  position: relative;
  text-align: left;
}

.right-icon {
  width: 1.11rem;
  height: 0.42rem;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.modular-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modular-item {
  width: 1.65rem;
  height: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  text-align: center;
  margin-bottom: 0.15rem;
  border-radius: 0.04rem;
  color: #ffffff;
  font-size: 0.17rem;
  text-align: left;
  padding-left: 0.2rem;
  padding-right: 0.1rem;
  box-sizing: border-box;
}

.modular-item:nth-child(1) span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: normal;
}

.modular-item:nth-child(1) {
  background: url(../assets/img/mxx_zz.png) no-repeat center;
  background-size: contain;
}

.modular-item:nth-child(2) {
  background: url(../assets/img/zrz_zz.png) no-repeat center;
  background-size: contain;
}

.modular-item:nth-child(3) {
  background: url(../assets/img/pxw_zz.png) no-repeat center;
  background-size: contain;
}

.modular-item:nth-child(4) {
  background: url(../assets/img/zgl_zz.png) no-repeat center;
  background-size: contain;
}

.modular-item:nth-child(5) {
  background: url(../assets/img/zsq_zz.png) no-repeat center;
  background-size: contain;
}

.modular-item:nth-child(6) {
  background: url(../assets/img/qmxz_zz.png) no-repeat center;
  background-size: contain;
}

.modular-item:nth-child(7) {
  background: url(../assets/img/xin-aiyuyin.png) no-repeat center;
  background-size: contain;
}

.modular-item:nth-child(8) {
  background: url(../assets/img/szcp.png) no-repeat center;
  background-size: contain;
}

.left-top-tips {
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  width: 0.25rem;
  display: none;
}

.center {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.to_be_master {
  margin: 0.2rem 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.to_be_master img {
  width: 3.45rem;
  height: 0.49rem;
  object-fit: contain;
}

.bigTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigTitle img {
  width: 3.595rem;
  object-fit: contain;
}

.smallTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallTitle img {
  width: 1.785rem;
  object-fit: contain;
}

.ywy {
  position: relative;

  width: 100%;
  box-sizing: border-box;
  padding: 0.2rem 0.315rem;
  font-size: 0.15rem;
  color: #ffffff;
  background-size: 100%;
  margin-bottom: 0.1rem;
}

.ywy::before {
  content: "";
  position: absolute;
  width: 0.2rem;
  height: 0.2rem;
  background: url(../assets/img/border_w.png) no-repeat;
  background-size: 100%;
  left: 0;
  bottom: 0;
}

.ywy::after {
  content: "";
  position: absolute;
  width: 0.2rem;
  height: 0.2rem;
  background: url(../assets/img/border_w.png) no-repeat;
  background-size: 100%;
  right: 0;
  top: 0;
  transform: rotate(180deg);
}

.border {
  position: absolute;
}

.ywy .top {
  width: calc(100% - 0.2rem);
  border-top: #ffffff solid 0.001rem;
  left: 0rem;
  top: 0rem;
}

.ywy .bottom {
  width: calc(100% - 0.2rem);
  border-bottom: #ffffff solid 0.001rem;
  right: 0rem;
  bottom: 0rem;
}

.ywy .left {
  height: calc(100% - 0.2rem);
  border-left: #ffffff solid 0.001rem;
  left: 0rem;
  top: 0rem;
}

.ywy .right {
  height: calc(100% - 0.2rem);
  border-right: #ffffff solid 0.001rem;
  right: 0rem;
  bottom: 0rem;
}

.ywy1 {
  background: url(../assets/img/kb.png) no-repeat;
  background-size: 100% 100%;
  padding: 0.1rem 0.15rem;
  padding-right: 0.08rem;
  box-sizing: border-box;
  min-height: 0.35rem;
  color: #fff;
  font-size: 0.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ywy1>span {
  width: 2.6rem;
}

.ywytj {
  width: 0.645rem;
  height: 0.38rem;
  background: url(../assets/img/ywytj.png) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 0.16rem;
  font-size: 0.12rem;
  color: #fff;
  text-align: center;
}

.bg_mast {
  margin-top: 0.2rem;
  background: url(../assets/img/huajuan.png) no-repeat;
  background-size: 100% 100%;
  height: 0.55rem;
  box-sizing: border-box;
  font-size: 0.15rem;
  line-height: 0.36rem;
  color: #952010;
  padding: 0.09rem 0.24rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-tips {
  width: 0.25rem;
  height: 0.25rem;
  margin-right: 0.05rem;
}

.pop-cy-rz .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pop-cy-rz .center input {
  width: 100%;
  height: 0.44rem;
  outline: none;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
  border: none;
  padding-left: 0.1rem;
  margin-top: 0.26rem;
  box-sizing: border-box;
}

.pop-cy-rz .pop-tishi {
  color: white;
  font-size: 0.14rem;
  margin-top: 0.2rem;
}

.pop-cy-rz .pop-tishi>img {
  width: 0.14rem;
  vertical-align: middle;
  margin-right: 0.06rem;
}

.pop-qrcode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: url(../assets/img/longtap.png) no-repeat;
  background-size: 100% 100%; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop-qrcode>div:nth-child(1) {
  width: 2.5rem;
}

.pop-qrcode img {
  width: 2.5rem;
  object-fit: contain;
}
</style>
