<script>
  import './assets/css/index.css'
  export default {
    
    created(){
      if(!localStorage.getItem('helpinfo') || localStorage.getItem('helpinfo') == '{}'){
        let date = new Date().getTime()
        localStorage.setItem('helptimer',date)
        this.axios.post('goods/getHelpInfoConfig').then((res)=>{
          let list = res.data.data.list
          let help = {}
          for(let i = 0; i < list.length; i++){
            help[list[i].key] = list[i].content
            help[list[i].key+'_intl'] = list[i].content_intl
          }
          localStorage.setItem('helpinfo',JSON.stringify(help))
        })
      }else{
        let date = new Date().getTime()
        if(!localStorage.getItem('helptimer')){
          localStorage.setItem('helptimer',date)
        }else if(date - parseInt(localStorage.getItem('helptimer')) > 60*1000*60 ){
          localStorage.setItem('helptimer',date)
          this.axios.post('goods/getHelpInfoConfig').then((res)=>{
            let list = res.data.data.list
            let help = {}
            for(let i = 0; i < list.length; i++){
              help[list[i].key] = list[i].content
              help[list[i].key+'_intl'] = list[i].content_intl
            }
            localStorage.setItem('helpinfo',JSON.stringify(help))
          })
        }
        
      }
      
    }
  }
</script>

<template>
  <div id="app">
    <keep-alive >
      <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"  />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />
  </div>
</template>


<style>
  #app{
   
     
  }
  body{
    font-size:0.16rem;
   
    word-wrap: break-word;

  }
  .weui-dialog{
    overflow: visible;
  }
  .weui-toast__content{
		margin: 0 0.05rem !important;
    margin-bottom:  0.05rem !important;
	}
  .weui-dialog__bd{
    -webkit-overflow-scrolling: touch;
    max-height: 60vh;
    overflow-y: auto;
  }
  .weui-dialog__btn_warn{
    color: #ff5152;
  }
  .return1 {
  width: 0.065rem;
  height: 0.12rem;
  margin-right: 0.1rem;
  display: inline-block;
}

  @media (orientation: portrait){
    .tran90 .weui-dialog{
      
			transform-origin: 0 0;
			
			
            -webkit-transform:rotate(90deg)  translate(calc(-50vh + 50%),calc(-50vw - 50%));
            -moz-transform: rotate(90deg)  translate(calc(-50vh + 50%),calc(-50vw - 50%));
            -ms-transform: rotate(90deg)  translate(calc(-50vh + 50%),calc(-50vw - 50%));
            transform:rotate(90deg) translate(calc(-50vh + 50%),calc(-50vw - 50%));
            transform-origin: 0% 0%;
    }
  }
</style>
