export default {
    main:{
        return:"Return",
        dl:"Download",
        fail:"Fail",
        new:"Personal ㅤㅤ News",
        title:"CCWC",
        titleWeb:"China Great Wall Cultural and Creative Competition Official Website",
        titleCenter:"CCWC",
        infoM:"In order to ensure that the author can contact you, please fill in true and valid information.",
        infoT:"In order to ensure the normal and safe financial process, please enter true and valid information, which can be modified after submission.",
        report:"Report",
        qrcode30:"Validity period of QR code: 30 minutes",
        qrcode5:"Validity period of QR code: 5 minutes",
        modify:"Modify ",
        modifyInfo:"Modify information",
        swma:"Switch master",
        delete:"Delete",
        history:"History",
        relT:"Release time:",
        agreeC:"Agree to comply",
        ahc:"Artist honor certificate:",
        Deposit:"Deposit time:",
        tfc:"Treasures for certificate:",
        gfc:"Grade of certificate :",
        gno:"Certificate No.",
        cta:"Complete the authorization",
        rf:"Review failed",
        ur:"Under review",
        mv:"Market valuation",
        sa:"Authorization",
        nm:"The current master",
        at:"amount to",
        noMatch:"The two passwords you entered do not match",
        iata:"I am the creator",
        iatc:"I am the collector",
        mya:"My Following",
        myc:"My Collection",
        myb:"My Buying",
        myt:"My Treasure",
        aoaa:"Authorizing other users to help manage author’s account",
        aoca:"Authorizing other users to help manage collector’s account",
        aa:"Authorized account",
        submit:"Submit",
        submita:"Submit audit",
        pfbs:"Pay for the blockchain service fees ￥",
        collectName:"Collector Name:",
        CollectPho:"Collector photos:",
        titleofC:"Title of collector:",
        collectIn:"Collector Introduction:",
        collectHon:"Collector Honors:",
        collectHonC:"Certificate of Collector:",
        newHead:"News headlines",
        nbu:"No bound users",
        nrr:"No recharge records",
        nftoM:"No follow yet, go and follow the master!",
        nctoT:"No collection, go and follow the treasure",
        nptob:"No purchase record yet, buy now",
        npr:"No purchase record",
        ntcn:"No treasure yet, create a treasure now!",
        nappin:"No application information",
        nyc:"Not yet certified",
        platTips:"All the text, labels, and pictures contained in this page are the information of the successful deposit of Treasure Center.",
        welcome:"Welcome",
        purshRe:"Request",
        purshMe:"message",
        noteSign:"Note: After your signature is approved, it can be signed onto the certificate.",
        noteAuth:"Note: Please do not provide the QR code to other users who do not require authorization.",
        noteRZ:"Note: Please do not provide the QR code to others. The QR code is disposable which valid within half an hour. After successful authentication, the collector authentication in Treasure Information will display the authenticator information.",
        notehold:"Note: The holder information display “customize” must be reviewed through the background;and will be immediately displayed in the treasure information after passing the review.",
        notepay:"Note: After the new holder completes the payment, the previous holder receives the application and agrees to complete the certification transfer.",
        expensesR:"Expenses record",
        robotAdd:"Add AI customer service service and complete registration under guidance, or click the red button below.",
        robotAddTip:"Add Great Wall Cultural and Creative Treasure Center",
        clickScan:"Click Scan Code to follow",
        tpc:"Treasure Preservation",
        tpcc:"Treasure Preservation Success",
        trc:"Treasure related certificates:",
        tM:"Treasure Management",
        afhct:"Apply for holder certification transfer",
        afthc:"Apply for Treasure Holder Certification",
        comfirm:"Confirm",
        dap:"Purchase",
        acts:"Confirm recharge",
        points:"Points",
        signoff:"Sign off",
        signSet:"Signature Settings",
        lefof:"Lifetime entry fee of 11888 yuan (special offer)",
        statistics:"Statistics",
        bban:"Be banned",
        unlockInfo:"To unlock the purchase request information, you need to pay the fee:￥",
        unbind:"Unbind",
        orderNo:"Order Number:",
        orderRe:"Order Record",
        ciur:"Certification information under review",
        cti:"Certified Treasure Information:",
        authtran:"Transfer",
        pfyci:"Please fill in your contact information",
        msypc:"Make sure that you have passed the certification on:",
        acc0:"Account 0",
        accauth:"Account Authorization",
        pcscc:"Enter the correct signature password and then click “confirm”; Your signature will be signed onto your Treasure Certificate.",
        approved:"Approved",
        gwtc:"Treasure Code:",
        lodqr:"Long press to download QR code",
        preview:"Preview",
        glmn:"get the latest message notification",
        noaldar:"(Reproduction, adaptation, and distribution are not allowed without permission)",
        draft:"(Draft)",
        copyT:"Copy or not: Treasure ",
        ntpp:"{N} points need to be paid",
        coptTips:"Copy a new treasure with the same information and a different Great Wall treasure code.",
        epcosr:"Each person can only send a purchase request for the same treasure once.",
        psygv:"pay success, you will be given {N} works AI voice",
        l20cc:" Chinese characters",
        dccp:"Consume {N} points",
        avsi:"AI Voice Service Introduction",
        avse:"AI voice service enabled",
        aisa:'"AI Voice Service Agreement"',
        uploading:"Uploading",
        uilt:"Images are less than 1m;",
        upafd:"Upload a picture and fill in a description",
        upsucc:"Upload succeeded",
        useafi:"Upload screenshot evidence and fill in instructions",
        updpot:"Upload partial detail pictures of Treasures",
        lastms:"Last month's commission",
        lasmt:"Total amount of last month",
        downqr:"Download QR code",
        downce:"Download Certificate",
        cfy:"Can't follow yourself",
        dcbsp:"Description cannot be submitted separately, please upload anti-counterfeiting image",
        fail:"Fail",
        personI:"Personal information",
        rspvp:"Reporting succeeded!<br> The platform will verify and process as soon as possible.",
        konwMore:"Learn more about the competition",
        qrpay:"QR code payment",
        vtoqrc:"Valid time of QR code: 30 minutes",
        tholderT:"Today's holder transfer",
        tnwt:"Number of new works added today",
        value:"Value",
        taow:"Total amount of works",
        tow:"Type of work:",
        author:"Author ",
        coauthor:'Co-author ',
        coauthor1:'Co-author 1',
        coauthor2:'Co-author 2',
        ains:"Author information needs to be supplemented. Set now?",
        authzb:"Author's Treasure",
        smipw:"Successfully modified the information, please wait for approval",
        inm:"Information not modified",
        iipcfi:"Incomplete information, please continue to fill in",
        infoedit:"Information Editing",
        modifySucc:"Modified successfully",
        yuan:"yuan",
        recharge:"Recharge",
        selectALL:"Select All",
        copN:"Company/Individual Name:",
        ctn:"Company Tax Number:",
        publicS:"Public sale",
        foaocgwc:"Follow the official account of China Great Wall Cultural and Creative Competition",
        ftheauth:"Follow author",
        followSuc:"Follow success",
        thinkTwice:"Think twice",
        dateofbirth:"date of birth",
        share:"Share",
        switchSucc:"Switch successfully",
        timeofcrea:"Time of creation",
        deleteTr:"Delete success",
        deleteSt:"Delete treasure",
        remcomm:"Remaining commission",
        rempoint:"Remaining points",
        loading:"Loading",
        csenword:"Contains sensitive words:",
        bchash:"Blockchain Hash",
        bctranre:"Blockchain transfer records",
        hissta:"Historical statistics",
        purchase:"Purchase",
        send:"Send",
        cancel: "Cancel",
        cancelSuc:"Cancel success",
        celebrity:"Celebrity ",
        celebrityAuth:"Famous Author",
        celebrityNew:"Celebrity news",
        celebrityTr:"Famous Treasures",
        celebrityIn:"Experts Introduction",
        syatca:"Send you a transfer certification application",
        tpitltu:"The picture is too large to upload .",
        copySucc:"Copy succeeded",
        copyTr:"Copy",
        copyLink:"Copy Link",
        female:"Female",
        fsplyci:"For service, please leave your contact information",
        fewc:"For example, wood carving",
        fullname:"Full name",
        formofadd:"Form of address",
        pwcnbb:"Password can not be blank",
        pwcsucc:"Password reset complete",
        pwsetsucc:"Password set successfully",
        pwrpwnc:"When the password is reset, the password needs to be changed to sign the Signature.",
        wrongPw:"Wrong password",
        pwnl6:"The password length cannot be less than 6 characters",
        password:"Password",
        soryyna:"Sorry, you are not the author",
        soryync:"Sorry, you are not a collector",
        soryyncinst:"Sorry, you are not a Collection Institution",
        soryiree:"Sorry, if you can not check this Great Wall treasure, please re-enter",
        tophnv:"The other party has not viewed",
        yearold:"years old",
        followed:"Followed",
        authcancel:"Authentication canceled",
        blocked:"Blocked",
        enabled:"Enabled",
        favorite:"Favorited",
        favore:"Favorite",
        selected:"Selected",
        platMainb:"Platform Maintenance Bulletin",
        age:"Age:",
        openai:"Open",
        openaiSucc:"Successfully opened",
        openser:"Start service",
        startdate:"Start Date",
        rvstai:"remaining voice service times are insufficient",
        nowholder:"Current holder:",
        wxpay:"WeChat payment",
        wxnickname:"WeChat nickname",
        wxrobot:"WeChat intelligent robot",
        required:"*",
        yhsapr:"You have submitted a purchase request.",
        yhsyapt:"You have submitted your application. Please try again later.",
        isLike:"You have liked",
        avshbe:"AI voice service haven’t been enabled",
        repairtips:"Your application has been submitted successfully, and the staff will contact you within 3 working days.",
        ravtimes:"Remaining times:",
        ttoavyuu:"The times of AI voice you have purchased has been used up",
        wanttobuy:"Want to buy",
        mymessage:"My message",
        or:"or",
        phoneNum:"Phone number:",
        phoneErr:"The phone number was entered incorrectly, please re-enter",
        scan:"Scan",
        scanM:"Scan the master's exclusive QR code",
        holder:"Holder",
        holderinoe:"The holder information cannot be empty",
        holderinshow:"Holder information display:",
        holdercanwatch:"Only the holder can view the ultra clear large image",
        htr:"Holder Certification Transfer Record",
        holdertran:"Certification Transfer",
        holderce:"Holder certification",
        holdertr:"Holder Transfer",
        holderNsu:"The holder has not submitted certification information yet",
        recommend:"Recommend",
        submiting:"Submitting",
        submitfail:"Submit failed",
        submitsucc:"Submit succeed",
        submitmg:"The submitted information contains sensitive words",
        subtips:"Tips",
        searching:"Searching",
        revoke:"Revoke",
        ofptal:"Operating too frequently. Please try again later",
        payfail:"Payment failed",
        paysucc:"Payment succeeded",
        collinedit:"Information editing",
        collinstedit:"Information editing",
        collsupp:"The collector information needs to be supplemented. Set now?",
        collinstsupp:"The Collection Institution information needs to be supplemented. Set now?",
        collsucc:"Collect successfully",
        colltime:"Collection time",
        colltre:"Collector's Treasures",
        putaway:"Collapse",
        effpre:"Effect Preview",
        newcer:"New Certificate",
        newMange:"News management",
        newLink:"News link:",
        oldpass:"Old password:",
        timechoose:"Time selection",
        nfpst:"Not for public sale",
        nodata:"No data available",
        notrenopen:" No treasures to open currently",
        nopurre:"No purchase record",
        updateSucc:"Update succeeded",
        recomm4:"You can only recommend  four collections.",
        lastva:"Latest valuation:",
        notheauth:"Not the author",
        caAuthTran:"(Collectors) Authorized Transfer|(Authors) Authorized Transfer",
        seemore:"Check more ",
        check:"Check",
        queryrecord:"Query records",
        ysnoremi:"Your signature review failed. Please modify it.",
        nosetpwnse:"you have not set up your signature password. Please set it now.",
        nosetsse:"You have not set up your signature. Please set it now.",
        lpssu:"Uploading image, please submit later",
        eachonlyre:"Each only requires",
        purchaser:"Purchaser:",
        purchTime:"Purchase time:",
        purchSour:"Purchasing Source:",
        npicwhite:"Note: The uploaded image must be a white solid color stamp image",
        picsize30:"Image size within 3M.",
        addrow:"Add a row",
        add:"Add to",
        eliminate:"Eliminate",
        fitaira:"Fill in author registration application",
        fitcira:"Fill in collector registration application",
        fitciira:"Fill in Collect institutional registration application",
        cotvtprm:"Click Confirm to view the purchase request message",
        ctounlock:"Unlock",
        livesucc:"Like succeeded",
        liked:"Like",
        trupsuwait:"Treasure uploaded successfully, please wait patiently for review",
        trvalue:"Valuation:",
        treidtwait:"Treasure has been successfully modified. Please wait patiently for review",
        trorvalue:"Treasure Original Market Valuation:",
        trname:"Treasure Name",
        trhbdetele:"Treasure has been deleted",
        trhbban:"The treasure has been banned",
        trhbtran:"Treasure has been transferred",
        trdes:"Treasure Description",
        trdata:"Treasure Data",
        trdatano10:"Treasure data cannot exceed 10 items",
        trrce:"Treasure related certificates",
        trphoto:"Treasure Photo",
        trfatotwmb:"The first authorized transfer of Treasure was made by:",
        trlevel:"Treasure Level:",
        trcode:"Treasure Code:",
        trhonor:"Treasure Honor",
        apptoauth:"Apply to become the author",
        apptocoll:"Apply to become the collector",
        apptocollinst:"Apply to become the Collect Institutional",
        appsufaplta:"Application submission failed. Please try again",
        appsusuccplta:"Application submitted successfully, please wait for processing,请等待处理",
        applytime:"Application time:",
        male:"Male",
        vtowocgwc:"Visit the official website of China Great Wall Cultural and Creative Competition ",
        gotit:"got it",
        determine:"Determine",
        cdeletetr:"Do you want to delete the treasure? (Deleting the treasure within 24 hours,points will be returned.)",
        aysutosub:"Are you sure to submit?",
        confIrm:"Confirm",
        confirmpwd:"Confirm password:",
        confirmandpay:"Confirm and pay",
        pointsgocz:"Insufficient points,  go to the recharge page",
        pointsrecharge:"point recharge",
        pchartips:"Points cannot be refunded after recharging. Please confirm carefully",
        ptrtc:"Pass the rare treasure certificate",
        fidmia10:"The first image defaults to the main image, and the image must less than 10M",
        aystep:"Step 1: the authorized person to scan by WeChat",
        arstep:"Step 1: Ask the certificate holder scan by WeChat",
        aystep3:"Step 3: The author receives the prompt then confirm the identity of the holder; complete the certification after confirmation.",
        sqstep3:"Step 3: Complete the binding of authorizers",
        zrstep3:"The collector receives a prompt then confirm the identity of the holder,complete the certification after confirmation.",
        cyzstep3:"The new holder pays the certification fee   ￥",
        allstep2:"Step 2: Please scan the following QR code",
        allstep4:"Step 4: The holder receives a prompt then confirm the identity of the new holder. If you agree to transfer the certification to the new holder, the certification will be completed.",
        suspsspwd:"Signature uploaded successfully, please set signature password",
        signeditsu:"Signature modified successfully",
        signeditsetpwd:"Signature modified successfully, please set signature password",
        signpwd:"Signature Password",
        signsucc:"Signature succeeded",
        detailUpload:"Details",
        detailappre:"Details Appreciation",
        bindsucc:"Binding succeeded",
        bindpage:"Bind Page",
        masterInfo:"Master Information",
        contactInfo:"contact:",
        userdefin:"user-defined",
        draftSasucc:"Draft saved successfully",
        ceofhon:"Certificate of honor",
        unbindSucc:"Unbind succeeded",
        orderNum:"Order Number",
        cerinfo:"Certification information",
        cersucc:"Certification successful",
        cerdisplay:"Certification display:",
        certrinfo:"Certified Treasure Information:",
        setSucc:"Setup succeeded",
        certificate:"Certificate",
        ceandnameup:"Certificate image and name must be submitted together",
        uptrholdinfo:"This function is to update the WeChat name or avatar of the holder in your treasure information. Would you like to update it?Note: user-defined names will not be updated.",
        trisdepc:"This treasure has been deleted and cannot be accessed. Please contact customer service for processing.",
        trisSigned:"The treasure has been signed",
        trisbanpc:"The treasure has been banned and cannot be accessed, please contact the customer service",
        ovstup:"Once the voice service is turned on, it cannot be undone.Please confirm that the Treasure selection is correct before turning it on.",
        pulsi:"Please upload the seal image",
        puaci:"Please upload anti-counterfeiting images",
        puwxpay:"Please use WeChat payment",
        peditsigntoup:"Please modify your signature before submitting",
        paoaf:"Please agree to the agreement first",
        pcaieditf:"Please complete the author information editing first",
        pccieditf:"Please finish editing collector information first",
        pccieditfinst:"Please finish editing Collection Institution information first",
        psf:"Please sign first",
        pupif:"Please unlock the purchase information first",
        penif:"Please enter news information first",
        psrp:"Please select recharge points first",
        piypwdlast6:"Please enter your password again",
        pftci:"Please fill in the complete information",
        pfiyname:"Please fill in your name",
        pctibsub:"Please complete the information before submitting",
        pftlv:"Please fill in the latest valuation",
        pauthlogin:"Please authorize login",
        pruwechat:"Please recharge using WeChat",
        pbintrotauth:"Please briefly introduce the author",
        pbintrotcoll:"Please briefly introduce your treasures",
        pbintroutr:"Please briefly introduce your treasures",
        pbdescbitem:"Please briefly describe the basic situation of the item no more than 200 characters.",
        pbdescbrepo:"Please enter a description of the reported content, up to 200 characters",
        pwdtips:"Please enter a password of no less than 6 digits",
        petauthname:"Please enter the author's name",
        penauthceofaw:"Please enter the author's certificate of award",
        petauthtitle:"Please enter the author's title",
        petmest:"Please enter the market estimate",
        piypw:"Please enter your password",
        piypwdlest6:"No less than 6 digits.",
        piypmess:"Please enter your purchase message",
        piyphone:"Please enter your mobile phone number",
        picollname:"Please enter a collector name",
        picolcewon:"Please enter the certificate that the collector has won.",
        pititleofcol:"Please enter the title of the collector",
        pinewtitle:"Please enter a news title",
        pinewlink:"Please enter a news link, such as: https://new.qq.com/article/...",
        pitrname:"Please enter the name of the treasure",
        pitrdata:"Please enter treasure data, such as: Weight: 12kg",
        pitrhbhon:"Please enter the treasure that has received honors",
        penofmast:"Please enter the name of the master",
        peacename:"Please enter a certificate name",
        pebanknum:"Please enter the bank card number",
        pebankName:"Please enter the name of the bank ",
        pedescantimap:"Please enter the description of the anti-counterfeiting map",
        pccoftr:"Please select the creation time of the treasure",
        pccolftr:"Please select the collection time of treasures",
        pebhofce:"Enter the blockchain hash",
        pegwtc:"Enter the Treasure Code",
        penameofyouwant:"Please enter the name of the master you want to view",
        peciname:"Please enter a company/individual name",
        pectnum:"Please enter the company tax number",
        penewadress:"Please enter news address",
        petfullmname:"Please enter the full master name",
        penterpwd:"Please enter password",
        peyphone:"Please enter your mobile phone number",
        pesignpwd:"Enter signature password",
        peestbid:"Please enter your estimated bid",
        pepurchmess:"Please enter a purchase message",
        pensearchctx:"Please enter the Great Wall Treasure Code or Blockchain Hash",
        peestPrice:"Please enter the estimated price",
        pcwotr:"Please select the treasure to turn on voice",
        bill:"Bill",
        purchtime:"Purchase time",
        purchNum:"Purchase",
        purchApply:"Purchase Application",
        purchMess:"Purchase message:",
        Cost:"Cost",
        numoftran:"Number of transfers:",
        pirceoftran:"Transfer amount",
        sendwAiAudio:"Send {N} works AI audio",
        ctrofvis:"Choose the treasure of voice services to open",
        chooseTr:"Choose Treasure",
        apprePic:"Appreciate Pictures",
        appremoretr:"Appreciate more treasures",
        gwcoa:"Great Wall Cultural Creation official account",
        gwtrsucccer:"The Treasure has been successfully certified",
        gwtrNo:"Great Wall Treasure Code",
        readTimes:"Reading times:",
        aalineff:"Audio already in effect",
        aginpro:"Audio generation in progress",
        estbid:"Estimated Bid:",
        copyacon:"(Copyright and Confidentiality)© 2022",
        trasure:"Treasure",
        master:"Master",
        sqroc:"Scan the QR code on the certificate",
        tppswsc:"This platform provides a service platform for promoting and showcasing works in the China Great Wall Cultural and Creative Competition",
        search:"Search",
        qrcode:"QRcode",
        collector:"Collector",
        honor:"honor",
        xMaster:"Master {X}",
        Hcertification:"Certificate",
        updateSignet:"Upload signet",
        works:"works",
        requireList:"Request List",
        Aiaudio:"AI Audio",
        phoneNums:"Phone number",
        submitbut:"Submit",
        trnestvalue:"Treasure Newest Valuation",
        MarketValue:"Market valuation",
        trMarkValue:"Market valuation",
        ccc:"Author data and work information will be permanently retained, included, displayed, promoted, traded, and traced through blockchain technology.",
        ccc2:"Collector data and work information will be permanently retained, included, displayed, promoted, traded, and traced through blockchain technology",
        ccc3:"Collect institutional data and work information will be permanently retained, included, displayed, promoted, traded, and traced through blockchain technology",
        gwtrNo1:"Treasure  ㅤCode",
        set:"Set ",
        confirmRech:"Recharge",
        viewDetail:"View Details",
        reportRes:"Please select the reason for reporting",
        curve:"Curve",
        signupsucc:"Signature uploaded successfully",
        record:"Record",
        copy:"Copy",
        saagip:"Successfully activated, audio generation in progress",
        cthnpta:"Currently Treasure has not activated AI voice service. Would you like to pay ¥ ${N} to activate it now",
        dredge:"Activate",
        actaivoice:"Activate AI voice",
        cthnaw:"Currently, Treasure has not activated AI voice service. Would you like to activate the service immediately after consuming it once (remaining {N} times)",
        aessumit:"After modifying the name or profile, the AI voice becomes invalid and needs to be regenerated. Are you sure you want to submit?",
        rzssubmit:"After modifying the name or profile, AI voice will become invalid and need to be reactivated for generation. Are you sure you want to submit?",
        rz:" Authentication",
        collectHonL:"Collector honors",
        collinst:"Collection Institution",
        collinstInfo:"Collection Institution Editing",
        collinstNew:"Institution News",
        apptoInst:"Apply to become the Collection Institution",
        transferP:"Transfer process",
        confirmTran:"Confirm information and transfer",
        intoCoInst:"Institutional Introduction",
        newToCoInst:"Collect institutional news",
        trToCoInst:"Collect institutional treasures",
        createTime:"Creation time",
        collinstTcoll:"You are already a master and cannot become a collection institution",
        collTcollinst:"You are already a collection institutional and cannot become a collector"
    },
    detail:{
      pro:"This work has been successfully promoted on the following global social media.",
      login:'Log in to the platform and search for "Great Wall Treasure Code" to find relevant content.'
    },
    zr:{
        tips:"Adjustable price range within 24 hours:￥"
    },
    master:{
        less11:"Less than 11 digits ",
        less50:"Less than 50 Chinese characters",
        honor50:"Fill in one honor content per line , less than 100 lines with less than 50 Chinese characters each line.",
        honor20:"Fill in one honor content per line less than 20 Chinese characters.",
        upce:"Upload no more than 10 certificates which below 10M",
        edit:"Author editing",
        name:"Author Name:",
        genter:"Author's gender:",
        photo:"Author's photo:",
        title:"Author Title:",
        profile:"Author profile:",
        honor:"Author Honors:",
        birth:"Birthday:",
    },
    recharge:{
        time:"Recharge time:",
        record:"Recharge Record",
        amount:"Recharge amount:",
    },
    sign:{
        tips:"You need to enter the signature password when signing the Treasure Certificate; make sure it is done by yourself. Please protect your password properly."
    }
};