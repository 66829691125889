<template>
    <div class="xcx_J">
        <div class="xcx_l">
            <div class="xcx_bg">
                
            </div>
            <img class="xcx_logo" src="../assets/img/jump_logo.png" alt="">
        </div>
        <div class="xcx_text">
            长城文创中心登录中，请稍后 ···
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted(){
        if(this.$route.query.code){
            let query = ''
            if(this.$route.query.web_tk_code){
                query='&web_tk_code='+this.$route.query.web_tk_code
            }
            this.axios.get('/auth/nt?code='+this.$route.query.code+query).then((res)=>{
                console.log(res)
                if(this.$route.query.web_tk_code){
                    wx.miniProgram.redirectTo({url: "../../pages/master/backToWeb"})
                    return
                }
                wx.miniProgram.redirectTo({url: "../../pages/phone/index?token="+res.data.data.token})
            })
            
        }
    }
}
</script>

<style scoped>
.xcx_J{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #390200;
}
@keyframes rote {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.xcx_bg{
    width: 1.13rem;
    height: 1.08rem;
    background: url(../assets/img/jump_bg.png) no-repeat;
    background-size: 100% 100%;
    animation: rote 4s infinite ease-in-out ;
    animation-delay: 0.5s;
}
.xcx_l{
    position: relative;
    display: flex;
    flex-direction: column;
}
.xcx_logo{
    width: 0.44rem;
    height: 0.57rem;
    position: absolute;
    left: 0.345rem;
    top: 0.255rem;
}
.xcx_text{
    margin-top: 0.275rem;
    writing-mode: vertical-lr;
    font-size: 0.16rem;
    color: #e9d9ae;
}
</style>