<template>
  <div class="model" v-show="is_notice">
    <div class="notice">
        <div class="notice_top">
            <img src="http://t.6a.com/94,011a48df4a5c6191.png" alt="" />
            <div>{{notice_title}}</div>
        </div>
        <div class="notice_content" v-html="content">
        </div>
        <div class="notice_btn" @click="closeNotice">
            确认
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            is_notice:false,
            content:'',
            notice_title:'',
            user_range:''
        }
    },
    mounted(){
        this.axios.get('auth/getPublicNotice?app_type=1').then((res)=>{
            if(JSON.stringify(res.data.data) == '{}'){
                return
            }
            this.notice_title = res.data.data.title
            this.user_range = res.data.data.user_range
            let newcontent = res.data.data.content.replace(/<img/gi,'<img style="max-width:100%;height:auto;margin:10px auto;display:block"')
            this.content = newcontent
            if(!res.data.data.content){
                return
            }
            if(localStorage.getItem('notice_content') == JSON.stringify(res.data.data.content)){
                return
            }
            localStorage.setItem('notice_content',JSON.stringify(res.data.data.content))
            this.is_notice = true
        })
    },
    methods:{
        closeNotice(){
            this.is_notice = false
        }
    }
}
</script>

<style>
.model{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.notice{
    width: 3.47rem;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 0.15rem;
    padding-bottom: 0.25rem;
    background: url(../assets/img/notice.png) no-repeat;
    background-size: 100% 100%;
}
.notice_top{
    flex-shrink: 0;
    height: 0.35rem;
    display: flex;
    align-items: center;
    padding: 0 0.32rem;
    width: 100%;
    box-sizing: border-box;
}
.notice_top img{
    width: 0.88rem;
    height: 0.33rem;
    object-fit: contain;
    margin-right: 0.17rem;
}
.notice_top div{
    padding-left: 0.16rem;
    font-size: 0.175rem;
    color: #fff;
    border-left: solid 1px #ffebb0;
    height: 0.35rem;
    display: flex;
    align-items: center;
}
.notice_content{
    flex: 1;
    flex-shrink: 0;
    overflow-y: auto;
    padding: 0 0.25rem;
    width: 100%;
    margin-top: 0.4rem;
    color: #fff;
    font-size: 0.15rem;
    box-sizing: border-box;
}
.notice_btn{
    width: 2.5rem;
    height: 0.4rem;
    margin-top: 0.15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../assets/img/view-more.png) no-repeat;
    background-size: 100% 100%;
    letter-spacing: 0.1rem;
    font-size: 0.15rem;
    color: #333333;
}
</style>