<template>
    <div class="changelang">
        <div class="c_main" @click="changeShow">
            <img src="../assets/img/changeLang.png" alt="">
            <div style="margin-right: 0.05rem;">{{ $i18n.locale | lang }}</div>
            <van-icon name="arrow-down"  />

        </div>
        <div v-show="isShow">
            <div class="c_main_item" @click="changeLang(lang)" v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
                {{ lang | lang }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                langs:[
                    'zn',
                    'en'
                ],
                isShow:false
            }
        },
        filters:{
            lang(value){
                switch (value) {
                    case 'en':
                        return 'English'
                        break;
                
                    default:
                        return '简体中文'
                        break;
                }
            }
        },
        methods:{
            changeShow(){
                this.isShow = !this.isShow
            },
            changeLang(lang){
                this.$i18n.locale = lang
                localStorage.setItem('lang',lang)
                this.changeShow()
            }
        }
    }
</script>

<style scoped>
.changelang{
    position:absolute;
    top: 0.1rem;
    right:0.1rem;
    font-size: 0.13rem;
    color: #fff;
}
.c_main{
    display: flex;
    align-items: center;
    background: rgba(0,0,0,0.3);
    height: 0.35rem;
    box-sizing: border-box;
    padding: 0 0.1rem;
    border-radius: 0.03rem;
}
.c_main img{
    width:0.22rem;
    height:0.22rem;
    margin-right: 0.1rem;
}
.c_main_item{
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.3);
    height: 0.35rem;
    box-sizing: border-box;
    padding: 0 0.1rem;
    text-align: center;
}
</style>