export default {
    main:{
        return:"返回",
        dl:"下载",
        fail:"不通过",
        new:"个人新闻",
        title:"中国长城文创大赛",
        titleWeb:"中国长城文创大赛官网",
        titleCenter:"中国长城文创珍宝中心",
        infoM:"为保证作者能与你取得联系，请填写真实有效的信息",
        infoT:"为保证财务流程的正常与安全，请输入真实有效的信息，提交后可再修改",
        report:"举报",
        qrcode30:"二维码有效期：30分钟",
        qrcode5:"二维码有效期：5分钟",
        modify:"修改",
        modifyInfo:"修改认证信息",
        swma:"切换老师",
        delete:"删除",
        history:"历史",
        relT:"发布时间：",
        agreeC:"同意遵守",
        ahc:"名家荣誉证书：",
        Deposit:"存证时间：",
        tfc:"存证珍宝：",
        gfc:"存证等级：",
        gno:"存证编号：",
        cta:"完成授权",
        rf:"审核不通过",
        ur:"审核中",
        mv:"市场估值",
        sa:"开始授权",
        nm:"当前老师",
        at:"总计",
        noMatch:"您两次输入密码不一致",
        iata:"我是创作者",
        iatc:"我是收藏家",
        mya:"我的关注",
        myc:"我的收藏",
        myb:"我的求购",
        myt:"我的珍宝",
        aoaa:"授权其他用户可帮忙管理作者账号",
        aoca:"授权其他用户可帮忙管理收藏家账号",
        aa:"授权账号",
        submit:"提交",
        submita:"提交审核",
        pfbs:"支付区块链服务费：￥",
        collectName:"收藏家名称：",
        CollectPho:"收藏家相片：",
        titleofC:"收藏家称号：",
        collectIn:"收藏家简介：",
        collectHon:"收藏家荣誉:",
        collectHonC:"收藏家荣誉证书：",
        newHead:"新闻标题",
        nbu:"无绑定用户",
        nrr:"暂无充值记录",
        nftoM:"暂无关注，快去关注老师吧！",
        nctoT:"暂无收藏，快去收藏珍宝吧！",
        nptob:"暂无求购，快去求购吧！",
        npr:"暂无消费记录",
        ntcn:"暂无珍宝，快去创建珍宝吧！",
        nappin:"暂无申请信息",
        nyc:"暂未认证",
        platTips:"本页面中包含的所有文字、标签、图片均为珍宝中心成功存证的信息",
        welcome:"欢迎您",
        purshRe:"求购申请",
        purshMe:"求购留言",
        noteSign:"注意：您的签名审核通过后，可以落款到证书上",
        noteAuth:"注意：请不要将二维码提供给其他不需要授权用户",
        noteRZ:"注意：请不要将二维码随意提供他人，二维码为一次性；半小时内有效；认证成功后，珍宝信息中藏友认证将显示认证者信息。",
        notehold:"注：持有者信息显示自定义需经过后台审核；审核通过后会立即显示在珍宝信息中",
        notepay:"注：支付完成后，上家收到申请，同意即完成新任持有者认证转让",
        expensesR:"消费记录",
        robotAdd:"添加AI客服并在指导下完成注册，或点击下方红色按钮",
        robotAddTip:"添加长城文创珍宝中心",
        clickScan:"点击扫码关注",
        tpc:"珍宝存证",
        tpcc:"珍宝存证成功",
        trc:"珍宝相关证书：",
        tM:"珍宝管理",
        afhct:"申请持有者认证转让",
        afthc:"申请获得珍宝持有者认证",
        comfirm:"确定",
        dap:"确定协议并购买",
        acts:"确认后系统审核，审核通过后即刻显示",
        points:"积分",
        signoff:"签名落款",
        signSet:"签名设置",
        lefof:"终身入驻费11888元(特惠) ",
        statistics:"统计",
        bban:"被封禁",
        unlockInfo:"解锁求购信息，需要支付费用：￥",
        unbind:"解除绑定",
        orderNo:"订单编号：",
        orderRe:"订单记录",
        ciur:"认证信息审核中",
        cti:"认证珍宝信息：",
        authtran:"认证转移",
        pfyci:"请填写您的联系方式",
        msypc:"请问您是否确定通过认证于：",
        acc0:"账号0",
        accauth:"账号授权",
        pcscc:"输入正确的签名密码后确定；您签名就会落款到您得珍品证书上",
        approved:"通过审核",
        gwtc:"长城珍宝编码：",
        lodqr:"长按下载二维码",
        preview:"预览",
        glmn:"获得最新留言通知",
        noaldar:"(未经许可，不得复制、改编与发布)",
        draft:"(草稿)",
        copyT:"请问是否复制: 珍宝",
        ntpp:"需支付{N}积分",
        coptTips:"复制一个一样信息，不同长城珍宝编码的新珍宝。",
        epcosr:"同个珍宝每人只能发送一次求购申请",
        psygv:"支付成功，同时赠送您{N}个作品AI语音:",
        l20cc:"中文字",
        dccp:"需要消耗{N}积分",
        avsi:"AI语音服务介绍",
        avse:"AI语音服务开启",
        aisa:"《AI语音服务协议》",
        uploading:"上传中",
        uilt:"上传图片<1m;",
        upafd:"上传图片并填写描述",
        upsucc:"上传成功",
        useafi:"上传截图证据并填写说明",
        updpot:"上传珍宝局部细节图片",
        lastms:"上月佣金",
        lasmt:"上月总额",
        downqr:"下载二维码",
        downce:"下载证书",
        cfy:"不可以关注自己",
        dcbsp:"不能单独提交描述说明，请上传防伪图片",
        fail:"不通过",
        personI:"个人简介",
        rspvp:"举报成功!<br>平台将尽快核实处理",
        konwMore:"了解更多比赛信息",
        qrpay:"二维码支付",
        vtoqrc:"二维码有效时间：30分钟",
        tholderT:"今日持有者转让",
        tnwt:"今日新增作品数",
        value:"价值",
        taow:"作品总数",
        tow:"作品类型:",
        author:"作者",
        coauthor:'联名作者',
        coauthor1:'联名作者 壹',
        coauthor2:'联名作者 贰',
        ains:"作者信息需补充，是否前往设置？",
        authzb:"作者珍宝",
        smipw:"信息修改成功，请等待审核",
        inm:"信息未修改",
        iipcfi:"信息未完善，请继续填写",
        infoedit:"信息编辑",
        modifySucc:"修改成功",
        yuan:"元",
        recharge:"充值",
        selectALL:"全选",
        copN:"公司/个人名称：",
        ctn:"公司税号：",
        publicS:"公开售卖",
        foaocgwc:"关注中国长城文创大赛公众号",
        ftheauth:"关注作者",
        followSuc:"关注成功",
        thinkTwice:"再想想",
        dateofbirth:"出生日期",
        share:"分享",
        switchSucc:"切换成功",
        timeofcrea:"创作时间",
        deleteTr:"删除成功",
        deleteSt:"删除珍宝",
        remcomm:"剩余佣金",
        rempoint:"剩余积分",
        loading:"加载中",
        csenword:"包含敏感词：",
        bchash:"区块链Hash",
        bctranre:"区块链转让记录",
        hissta:"历史统计",
        purchase:"去购买",
        send:"发送",
        cancel: "取消",
        cancelSuc:"取消成功",
        celebrity:"名家",
        celebrityAuth:"名家作者",
        celebrityNew:"名家新闻",
        celebrityTr:"名家珍宝",
        celebrityIn:"名家简介",
        syatca:"向您发送转让认证申请",
        tpitltu:"图片太大,上传失败",
        copySucc:"复制成功",
        copyTr:"复制珍宝",
        copyLink:"复制链接",
        female:"女性",
        fsplyci:"如需服务，请留下您的联系方式",
        fewc:"如，木雕",
        fullname:"姓名",
        formofadd:"姓名称呼：",
        pwcnbb:"密码不能为空",
        pwcsucc:"密码修改成功",
        pwsetsucc:"密码设置成功",
        pwrpwnc:"密码重置后需要修改密码才能落款签名",
        wrongPw:"密码错误",
        pwnl6:"密码长度不能少于6位",
        password:"密码：",
        soryyna:"对不起，您不是作者",
        soryync:"对不起，您不是收藏家",
        soryyncinst:"对不起，您不是收藏机构",
        soryiree:"对不起，查无此长城珍宝，请重新输入",
        tophnv:"对方还未查看",
        yearold:"岁",
        followed:"已关注",
        authcancel:"已取消认证",
        blocked:"已封禁",
        enabled:"已开启",
        favorite:"已收藏",
        favore:"收藏",
        selected:"已选",
        platMainb:"平台维护公告",
        age:"年龄：",
        openai:"开启",
        openaiSucc:"开启成功",
        openser:"开启服务",
        startdate:"开始日期",
        rvstai:"当前剩余语音服务次数不足",
        nowholder:"当前持有者：",
        wxpay:"微信支付",
        wxnickname:"微信昵称",
        wxrobot:"微信智能机器人",
        required:"必填",
        yhsapr:"您已提交求购申请",
        yhsyapt:"您已提交申请，请稍后再试",
        isLike:"您已点赞",
        avshbe:"您当前暂未开启AI语音服务",
        repairtips:"您的申请已提交成功，工作人员会在3个工作日内联系您。",
        ravtimes:"您的语音剩余次数：",
        ttoavyuu:"您购买的次数已用完",
        wanttobuy:"想要购买",
        mymessage:"我的留言",
        or:"或",
        phoneNum:"手机号码：",
        phoneErr:"手机号输入有误，请重新输入",
        scan:"扫一扫",
        scanM:"扫一扫大师专属二维码",
        holder:"持有者",
        holderinoe:"持有者信息不能为空",
        holderinshow:"持有者信息显示：",
        holdercanwatch:"持有者才能观看超清大图",
        htr:"持有者认证转让记录",
        holdertran:"持有者认证转让",
        holderce:"持有者认证",
        holdertr:"持有者转让",
        holderNsu:"持有者还未提交认证信息",
        recommend:"推荐",
        submiting:"提交中…",
        submitfail:"提交失败",
        submitsucc:"提交成功",
        submitmg:"提交的信息包含敏感词",
        subtips:"提示",
        searching:"搜索中…",
        revoke:"撤销",
        ofptal:"操作太频繁，请稍后再试",
        payfail:"支付失败",
        paysucc:"支付成功",
        collinedit:"收藏家信息编辑",
        collinstedit:"收藏机构信息编辑",
        collsupp:"收藏家信息需补充，是否前往设置？",
        collinstsupp:"收藏机构信息需补充，是否前往设置？",
        collsucc:"收藏成功",
        colltime:"收藏时间",
        colltre:"收藏珍宝",
        putaway:"收起",
        effpre:"效果预览",
        newcer:"新增证书",
        newMange:"新闻管理",
        newLink:"新闻链接：",
        oldpass:"旧密码：",
        timechoose:"时间选择",
        nfpst:"暂不公开售卖",
        nodata:"暂无数据",
        notrenopen:"暂无珍宝需要开启",
        nopurre:"暂无购买记录",
        updateSucc:"更新成功",
        recomm4:"最多只能推荐四个藏品",
        lastva:"最新估值：",
        notheauth:"未成为作者",
        caAuthTran:"(收藏家本人)  授权转让|(作者本人)  授权转让",
        seemore:"查看更多",
        check:"查看",
        queryrecord:"查询记录",
        ysnoremi:"检测到您的签名审核不通过，现在前往修改",
        nosetpwnse:"检测您未设置您的签名密码，现在前往设置。",
        nosetsse:"检测您未设置您的签名，现在前往设置。",
        lpssu:"正在上传图片中，请稍后再提交",
        eachonlyre:"每个仅需",
        purchaser:"求购人：",
        purchTime:"求购时间：",
        purchSour:"求购来源：",
        npicwhite:"注意：上传图片必须为白色 纯色",
        picsize30:"印章图片；图片大小3M以内",
        addrow:"添加一行",
        add:"添加",
        eliminate:"清除",
        fitaira:"点击填写作者信息注册申请",
        fitcira:"点击填写收藏家信息注册申请",
        fitciira:"点击填写收藏机构信息注册申请",
        cotvtprm:"点击确定前往查看求购留言",
        ctounlock:"点击解锁",
        livesucc:"点赞成功",
        liked:"点赞",
        trupsuwait:"珍宝上传成功，请耐心等待审核",
        trvalue:"珍宝估值：",
        treidtwait:"珍宝修改成功，请耐心等待审核",
        trorvalue:"珍宝原市场估值：",
        trname:"珍宝名称",
        trhbdetele:"珍宝已删除",
        trhbban:"珍宝已封禁",
        trhbtran:"珍宝已转移",
        trdes:"珍宝描述",
        trdata:"珍宝数据",
        trdatano10:"珍宝数据不能超过10项",
        trrce:"珍宝相关证书",
        trphoto:"珍宝相片",
        trfatotwmb:"珍宝第 1 次授权转让是由：",
        trlevel:"珍宝等级：",
        trcode:"珍宝编码：",
        trhonor:"珍宝荣誉",
        apptoauth:"申请成为作者",
        apptocoll:"申请成为收藏家",
        apptocollinst:"申请成为收藏机构",
        appsufaplta:"申请提交失败，请重试",
        appsusuccplta:"申请提交成功,请等待处理",
        applytime:"申请时间：",
        male:"男性",
        vtowocgwc:"直接访问中国长城文创大赛官网",
        gotit:"知道了",
        determine:"确定",
        cdeletetr:"确定要删除珍宝吗？(珍宝创建24小时内，删除会退回积分)",
        aysutosub:"确定要提交吗？",
        confIrm:"确认",
        confirmpwd:"确认密码：",
        confirmandpay:"确认并支付",
        pointsgocz:"积分不足,是否前往充值页面",
        pointsrecharge:"积分充值",
        pchartips:"积分充值后不可退款请仔细确认",
        ptrtc:"稀有珍宝 存证通过",
        fidmia10:"第一张默认为主图，上传图片<10M;",
        aystep:"第一步：请被授权者使用微信扫一扫",
        arstep:"第一步：请认证持有者使用微信扫一扫",
        aystep3:"第三步：作者收到提示；确认持有者身份；确认后则完成认证",
        sqstep3:"第三步：完成授权者绑定",
        zrstep3:"第三步：收藏家收到提示；确认持有者身份；确认后则完成认证",
        cyzstep3:"第三步：新任持有者支付认证费用￥",
        allstep2:"第二步：请扫一扫以下二维码",
        allstep4:"第四步：持有者收到提示；确认新任持有者身份；同意转让认证持有权与新任持有者，则完成认证。签名上传成功",
        suspsspwd:"签名上传成功,请设置签名密码",
        signeditsu:"签名修改成功",
        signeditsetpwd:"签名修改成功,请设置签名密码",
        signpwd:"签名密码",
        signsucc:"签名成功",
        detailUpload:"细节上传",
        detailappre:"细节鉴赏",
        bindsucc:"绑定成功",
        bindpage:"绑定页面",
        masterInfo:"老师信息",
        contactInfo:"联系方式:",
        userdefin:"自定义",
        draftSasucc:"草稿保存成功",
        ceofhon:"荣誉证书",
        unbindSucc:"解除绑定成功",
        orderNum:"订单编号",
        cerinfo:"认证信息",
        cersucc:"认证成功",
        cerdisplay:"认证显示：",
        certrinfo:"认证珍宝信息：",
        setSucc:"设置成功",
        certificate:"证书",
        ceandnameup:"证书图片和名称必须一起提交",
        uptrholdinfo:"该功能是更新您珍宝信息中持有者的微信名或头像，请问是否更新？注：自定义名称不会更新。",
        trisdepc:"该珍宝已删除，无法访问，请联系客服处理",
        trisSigned:"该珍宝已落款签名",
        trisbanpc:"该珍宝已被封禁，无法访问，请联系客服处理",
        ovstup:"语音服务一经开启无法撤销<br>请确认珍宝选择无误后再开启",
        pulsi:"请上传印章图片",
        puaci:"请上传防伪图片",
        puwxpay:"请使用微信支付",
        peditsigntoup:"请修改签名后再提交",
        paoaf:"请先同意遵守协议",
        pcaieditf:"请先完成作者信息编辑",
        pccieditf:"请先完成收藏家信息编辑",
        pccieditfinst:"请先完成收藏机构信息编辑",
        psf:"请先签名",
        pupif:"请先解锁求购信息",
        penif:"请先输入新闻信息",
        psrp:"请先选择充值积分",
        piypwdlast6:"请再次输入您的密码;最少6位数字",
        pftci:"请填入完整信息",
        pfiyname:"请填写您的姓名",
        pctibsub:"请填写完整信息再提交",
        pftlv:"请填写最新估值",
        pauthlogin:"请授权登录",
        pruwechat:"请用微信手机端进行充值",
        pbintrotauth:"请简单介绍一下作者",
        pbintrotcoll:"请简单介绍一下收藏家",
        pbintroutr:"请简单介绍一下您的珍宝",
        pbdescbitem:"请简单描述一下物品的基本情况,最多200个字符",
        pbdescbrepo:"请输入举报内容相关说明，最多200个字符",
        pwdtips:"请输入不少于6位的密码",
        petauthname:"请输入作者名称",
        penauthceofaw:"请输入作者曾获得奖状",
        petauthtitle:"请输入作者的称号",
        petmest:"请输入市场估值",
        piypw:"请输入您的密码",
        piypwdlest6:"请输入您的密码;最少6位数字",
        piypmess:"请输入您的购买留言",
        piyphone:"请输入手机号码",
        picollname:"请输入收藏家名称",
        picolcewon:"请输入收藏家曾获得奖状",
        pititleofcol:"请输入收藏家的称号",
        pinewtitle:"请输入新闻标题",
        pinewlink:"请输入新闻链接,如：https://new.qq.com/article/...",
        pitrname:"请输入珍宝名称",
        pitrdata:"请输入珍宝数据，如：重量：12kg",
        pitrhbhon:"请输入珍宝曾获得荣誉",
        penofmast:"请输入大师的名称",
        peacename:"请输入证书名称",
        pebanknum:"请输入银行卡号",
        pebankName:"请输入银行名称",
        pedescantimap:"请输入防伪图的相关描述或说明",
        pccoftr:"请选择珍宝创作时间",
        pccolftr:"请选择珍宝收藏时间",
        pebhofce:"输入证书上的区块链Hash",
        pegwtc:"输入证书上的长城珍宝编码",
        penameofyouwant:"请输入你要查看的大师名称",
        peciname:"请输入公司/个人名称",
        pectnum:"请输入公司税号",
        penewadress:"请输入完整的新闻地址",
        petfullmname:"请输入完整老师名称",
        penterpwd:"请输入密码",
        peyphone:"请输入您的手机号码",
        pesignpwd:"请输入您的签名密码",
        peestbid:"请输入您的预估出价",
        pepurchmess:"请输入购买留言",
        pensearchctx:"请输入长城珍宝编码或区块链Hash",
        peestPrice:"请输入预估价格",
        pcwotr:"请选择要开启语音的珍宝",
        bill:"账单",
        purchtime:"购买时间",
        purchNum:"购买次数",
        purchApply:"购买申请",
        purchMess:"购买留言：",
        Cost:"费用",
        numoftran:"转让次数：",
        pirceoftran:"转让金额",
        sendwAiAudio:"送作品AI音频{N}个",
        ctrofvis:"选择开启的语音服务的珍宝",
        chooseTr:"选择珍宝",
        apprePic:"鉴赏图片",
        appremoretr:"鉴赏更多珍宝",
        gwcoa:"长城文创公众号",
        gwtrsucccer:"长城珍宝存证成功",
        gwtrNo:"长城珍宝编号：",
        readTimes:"阅读次数：",
        aalineff:"音频已生效",
        aginpro:"音频生成中",
        estbid:"预估出价：",
        copyacon:"（版权与保密） © 2022",
        trasure:"珍宝",
        master:"老师",
        sqroc:"扫一扫证书上的二维码",
        tppswsc:"本平台为中国长城文创大赛提供推介展示作品的服务平台",
        search:"查询",
        qrcode:"二维码",
        collector:"收藏家",
        honor:"荣誉",
        xMaster:"{X}老师",
        Hcertification:"持有者认证",
        updateSignet:"上传印章",
        works:"个作品",
        requireList:"求购申请列表",
        Aiaudio:"AI语音",
        phoneNums:"手机号码",
        submitbut:"提交",
        trnestvalue:"珍宝最新估值",
        MarketValue:"市场估值变化",
        trMarkValue:"珍宝市场估值曲线",
        ccc:"作者资料及作品信息将通过区块链技术实现永久留存、收录、展示、宣传、交易及溯源",
        ccc2:"收藏家资料及作品信息将通过区块链技术实现永久留存、收录、展示、宣传、交易及溯源",
        ccc3:"收藏机构资料及作品信息将通过区块链技术实现永久留存、收录、展示、宣传、交易及溯源",
        gwtrNo1:"长城珍宝编号",
        set:"设置",
        confirmRech:"确认充值",
        viewDetail:"查看详情",
        reportRes:"请选择举报理由",
        curve:"曲线",
        signupsucc:"签名上传成功",
        record:"历史",
        copy:"复制",
        saagip:"开通成功，音频生成中",
        cthnpta:"当前珍宝未开通AI语音服务，是否支付￥{N}立即开通",
        dredge:"开通",
        actaivoice:"开通AI语音",
        cthnaw:"当前珍宝未开通AI语音服务，是否消耗一次服务立即开启(剩余{N}次)",
        aessumit:"修改名称或简介后AI语音会失效，需要重新生成，确定要提交吗？",
        rzssubmit:"修改名称或简介后AI语音会失效，需要重新开通生成，确定要提交吗？",
        rz:"认证",
        collectHonL:"收藏家荣誉",
        collinst:"收藏机构",
        collinstInfo:"收藏机构信息编辑",
        collinstNew:"机构新闻",
        apptoInst:"申请成为收藏机构",
        transferP:"转让流程",
        confirmTran:"确认信息并转让",
        intoCoInst:"收藏机构简介",
        newToCoInst:"收藏机构新闻",
        trToCoInst:"收藏机构珍宝",
        createTime:"创建时间",
        collinstTcoll:"您已经是老师，不能成为收藏机构",
        collTcollinst:"您已经是收藏机构，不能成为收藏家"
    },
    detail:{
      pro:"本作品已于以下全球社交媒体成功推广 ",
      login:"登录平台搜索“长城珍宝编码”可查询相关内容"
    },
    zr:{
        tips:"24小时内可调整区间：￥"
    },
    master:{
        less11:"小于11位数",
        less50:"小于50个中文字",
        honor50:"一行一荣誉；<100行；每行<50中文字",
        honor20:"一行一荣誉；每行<20中文字",
        upce:"上传证书最多10张；小于10M",
        edit:"作者信息编辑",
        name:"作者名称：",
        genter:"作者性别：",
        photo:"作者相片：",
        title:"作者称号：",
        profile:"作者简介：",
        honor:"作者荣誉：",
        birth:"出生日期：",
    },
    recharge:{
        time:"充值时间：",
        record:"充值记录",
        amount:"充值金额",
    },
    sign:{
        tips:"在珍宝证书落款时需输入签名密码；确保是您本人操作。请妥善保护好您的密码。"
    }
};