<template>
  <div class="out">
    <div class="smallTitle">
      <img
        src="../assets/img/logo-icon.png"
        alt=""
      />
    </div>
    <div class="bigTitle">
      <img
        src="../assets/img/bigtitle.png"
        alt=""
        srcset=""
      />
    </div>
    <div class="tag-wrap">
      <div class="search-treasure" :class="showSearchTeacher ? '' : 'active' " @click="showSearchTeacher = false">{{$t('main.trasure')}}</div>
      <div class="search-teacher" :class="showSearchTeacher ? 'active' : '' " @click="showSearchTeacher = true">{{$t('main.master')}}</div>
    </div>
    <SearchTreasure v-show="!showSearchTeacher"></SearchTreasure>
	  <SearchTeacher v-show="showSearchTeacher"></SearchTeacher>
    <div class="icon_list">
     
      <div class="icon_flex">
        <img
          src="../assets/img/ydzc.png"
          alt=""
        />

        <img
          src="../assets/img/ccwl_b.png"
          alt=""
        />
        <img
          src="../assets/img/ccwc_b.png"
          alt=""
        />
        <!-- <img src="../assets/img/cwxh.png" alt="" > -->
      </div>

      <div class="repair">
        <div>
          <img class="guanbi" src="../assets/img/guanbi.png" alt="" @click="guanbiBtn" v-if="!showBtn">
        </div>
        <img class="repair-rukou" src="../assets/img/repair-rukou-logo.png" @click="repairBtn" alt="" :class="showBtn ? 'active' : '' ">
      </div>

      <div class="text">
        <span>{{$t('main.copyacon')}}<br /></span>
        {{$t('main.tppswsc')}}<br />
        {{$t('main.platTips')}}<br />
        <span>{{$t('main.noaldar')}}</span>
      </div>
    </div>
    <changelang></changelang>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchTreasure from "../components/SearchTreasure";
import SearchTeacher from "../components/SearchTeacher";
import changelang from "../components/changelang.vue"
export default {
  name: "Home",
  components: {
    SearchTreasure,
	SearchTeacher,
  changelang
  },
  data() {
    return {
		showSearchTeacher: false,
    showBtn:false
	};
  },
  mounted() {
    this.height = document.documentElement.clientHeight + "px";

    let url = location.protocol + "//" + location.host;
    this.axios
      .post("/auth/wechatConfig", {
        url: url + this.$store.state.jssdkUrl,
        jsApiList: ["scanQRCode"],
      })
      .then((res) => {
        let json = JSON.parse(res.data.data.data);

        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: json.appId, // 必填，公众号的唯一标识
          timestamp: json.timestamp, // 必填，生成签名的时间戳
          nonceStr: json.nonceStr, // 必填，生成签名的随机串
          signature: json.signature, // 必填，签名
          jsApiList: json.jsApiList, // 必填，需要使用的 JS 接口列表
        });
      });
    // this.axios.post("/auth/testLogin",{
    // 	id:'oRG575pnb9oraacnbVRTOxXavZ24'
    // }).then((res) => {
    //   console.log(res);
    //   window.localStorage.setItem('user_token',res.data.data.token)
    // });
  },
  methods: {
    repairBtn(){
      if(this.showBtn){
        this.showBtn = false
        return
      }
      this.$router.push("/repair")
    },
    guanbiBtn(){
      this.showBtn = true
    },
  },
};
</script>
<style scoped>
.out {
  padding: 0.2rem 0.15rem;
  box-sizing: border-box;
  min-height: 100vh;
  box-sizing: border-box;
  background: url(../assets/img/home_bg.png) no-repeat center;
  background-size: cover;
  position: relative;
  /* filter:grayscale(100%); */
}
.or-box {
  font-size: 0.12rem;
  position: absolute;
  top: 0.57rem;
  left: 50%;
  transform: translateX(-50%);
}
.flex {
  display: flex;
}

.just-sp {
  justify-content: space-between;
}
.bigTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bigTitle img {
  width: 3.595rem;
  object-fit: contain;
}
.tag-wrap {
	background-color: rgba(0,0,0, .2);
	border-radius: 0.05rem;
	padding: 0.06rem;
	display: flex;
}
.tag-wrap div{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 50%;
	border-radius: 0.05rem;
	height: 0.45rem;
	color: white;
  font-weight: 600;
  font-size: 0.17rem;
}
.tag-wrap .active{
	background-color: rgba(255, 255, 255, .4);
}
.smallTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.smallTitle img {
  width: 1.21rem;
  height: 0.46rem;
  object-fit: contain;
}
.cirSvg {
  width: 0.175rem;
  height: 0.175rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #409eff; */
  border-radius: 50%;
  margin: 0 0.06rem;
  margin-left: 0.1rem;
  margin-right: 0.13rem;
}
.cirSvg > img {
  width: 0.175rem;
  object-fit: contain;
}
.cirSvg svg {
  width: 0.14rem;
  height: 0.14rem;
}

.button_scan {
  margin-bottom: 0.32rem;
  cursor: pointer;
  background: #ffb15f;
  height: 0.5rem;
  align-items: center;
  color: #fff;
  border-radius: 0.1rem;
}

.button_scan > div:nth-child(2) {
  font-size: 0.15rem;
  width: 0.92rem;
}

.button_scan > div:nth-child(3) {
  flex: 1;
  display: flex;
  /* justify-content: center; */
  font-size: 0.12rem;
}

.Title {
  font-size: 0.15rem;
  position: relative;
  margin-top: -0.05rem;
}
.cc-wc {
  color: #eec194;
  font-size: 0.15rem;
}
.English {
  color: #754c4f;
  position: absolute;
  top: 0.08rem;
  text-align: left;
  left: 0.35rem;
}

.Chinese {
  color: #ffffff;
  left: 0;
  position: relative;
}

.input_scan {
  height: 0.5rem;
  background: #fff;
  align-items: center;
  border-radius: 0.05rem;
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4); */
  background: #eeeeee;
  box-sizing: border-box;
  padding-right: 0.16rem;
  position: relative;
}

.input_s {
  margin: 0 0.1rem;
  display: flex;
  align-items: center;
  margin-right: 0.13rem;
}
.input_s > img {
  width: 0.17rem;
  object-fit: contain;
}
.input_s svg {
  width: 0.24rem;
  height: 0.24rem;
}

.input_scan > div:nth-child(2) {
  width: 1.7rem;
  text-align: left;
  font-size: 0.14rem;
}

.input_scan > input {
  font-size: 0.12rem;
}

.sou {
  justify-content: center;

  margin: 0 auto;
  margin-top: 0.3rem;
  width: 3.2rem;
  height: 0.48rem;
  background: url(../assets/img/check.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.text_sou {
  width: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 1rem;
  height: 0.39rem;
}

.logo img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cx-box {
  background: rgba(255, 255, 255, 0.6);

  padding: 0.2rem 0.15rem;
  border-radius: 0.1rem;
  margin-top: 0.175rem;
}
.zf-jc {
  font-size: 0.12rem;
  position: absolute;
  top: 0.52rem;
  right: 0;
}
.icon_list {
  box-sizing: border-box;
  margin-right: -0.15rem;
  margin-left: -0.15rem;
  width: 100vw;
  padding: 0.1rem 0.15rem;

  margin-top: 0.2rem;
  /* background: rgba(54,98,236,0.4); */
}
.icon_list img {
  display: block;
  height: 0.26rem;
  object-fit: contain;
}
.logo img {
  width: 1rem;
  height: auto;
}
.icon_list .text {
  margin-top: 0.12rem;
  font-size: 0.11rem;
  color: #fff;
  text-align: center;
}
.icon_list .icon_flex {
  margin-top: 0.1rem;
  display: flex;
  justify-content: center;
}
.icon_list .icon_flex img {
  margin: 0 0.1rem;
}
.repair{
  position: fixed;
  bottom: 0.5rem;
  right: 0.02rem;
}
.repair .repair-rukou{
  width: 0.6rem;
  height: 0.7rem;
  position: relative;
}
.repair .repair-rukou.active{
	right: -0.4rem;
}
.repair  div{
	position: relative;
}
.repair  div .guanbi{
	width: 0.15rem;
	position: absolute;
	left: -0.06rem;
	top: -0.14rem;
  z-index: 1;
}
</style>
