<template>
  <div>
    <div class="cx-box">
      <div class="flex button_scan" @click="scan">
        <div class="cirSvg">
          <img src="../assets/img/sys_r.png" alt />
        </div>
        <div style="color: #171717">{{$t('main.scan')}}</div>
        <div style="color: #5f5f5f">{{ $t('main.sqroc') }}</div>
      </div>
      <div class="flex input_scan" style="margin-top: 0">
        <div class="input_s">
          <img src="../assets/img/bm_r.png" alt />
        </div>
        <div>{{$t('main.gwtrNo1')}}</div>
        <input
          type="text"
          class="weui-input"
          v-model="serial_number"
          :placeholder="$t('main.pegwtc')"
          @input="zfChange"
          maxlength="11"
        />
        <span class="zf-jc">{{ bmLength }}/11</span>
        <div class="or-box">{{$t('main.or')}}</div>
      </div>

      <div class="flex input_scan" style="margin-top: 0.3rem">
        <div class="input_s">
          <img src="../assets/img/erm_r.png" alt />
        </div>
        <div>{{$t('main.bchash')}}</div>
        <input
          type="text"
          class="weui-input"
          v-model="uuid"
          :placeholder="$t('main.pebhofce')"
          @input="zfChange"
          maxlength="32"
        />
        <span class="zf-jc">{{ hashLength }}/32</span>
      </div>
      <!-- <div
      class="sou flex"
      @click="search"
    >
      </div>-->
      <div class="sou flex" @click="search">{{ $t('main.search') }}</div>
      <!-- <div class="text_sou">
					<div>查</div>
					<div>询</div>
      </div>-->
    </div>

    <div class="pop-pus" v-show="showPrpPus">
      <div class="bg-img">
        <div class="title-wrap">
          <div class="title-prompt">{{ prpText }}</div>
        </div>
        <div class="sure-logo">
          <div class="sure_div" @click="concatShow">{{ $t('main.confIrm') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uuid: "",
      serial_number: "",
      height: "100vh",
      bmLength: 0,
      hashLength: 0,
      loading: false,
      ispost: false,
      showPrpPus: false,
      prpText: ""
    };
  },
  mounted() {},
  methods: {
    zfChange() {
      this.bmLength = this.serial_number.length;
      this.hashLength = this.uuid.length;
    },
    scan() {
      if(!this.$isWeiXin()){
        weui.alert('请在微信浏览器打开')
        return
      }
      wx.scanQRCode({
        needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"] // 可以指定扫二维码还是一维码，默认二者都有
        // success: (res) => {
        // 	var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果

        // 	let url = result.split('/detail/')
        // 	this.$router.push('/detail/' + url[1])
        // }
      });
    },
    concatShow() {
      this.showPrpPus = false;
    },
    async search() {
      if (this.ispost) {
        return;
      }
      if (this.uuid || this.serial_number) {
        this.ispost = true;
        this.loading = weui.loading(this.$t("main.searching"));
        if (this.serial_number) {
          if (this.serial_number.length == 11) {
             let newdata = ''
              await this.axios.post('common/encrypt',{serial_number:this.serial_number}).then((res)=>{
                newdata = res.data.data.data_encrypted
              })
            this.axios
              .get("/goods/info?data=" + newdata)
              .then(res => {
                if (res.data.code == -1) {
                  this.loading.hide();
                  this.ispost = false;
                  // weui.alert(this.$t('main.soryiree'), {
                  //   buttons: [
                  //     {
                  //       label: "我知道了",
                  //       type: "primary",
                  //     },
                  //   ],
                  // });
                  this.prpText = this.$t("main.soryiree");
                  this.showPrpPus = true;
                } else {
                  this.loading.hide();
                  this.ispost = false;
                  this.$router.push(`/detail/${res.data.data.id}?status=1`);
                }
              })
              .catch(err => {
                this.loading.hide();
                this.ispost = false;
              });
          } else {
            this.axios
              .get("goodsder/info?serial_number=" + this.serial_number)
              .then(res => {
                if (res.data.code == -1) {
                  this.loading.hide();
                  this.ispost = false;
                  // weui.alert(this.$t('main.soryiree'), {
                  //   buttons: [
                  //     {
                  //       label: "我知道了",
                  //       type: "primary",
                  //     },
                  //   ],
                  // });
                  this.prpText = this.$t("main.soryiree");
                  this.showPrpPus = true;
                } else {
                  this.loading.hide();
                  this.ispost = false;
                  this.$router.push(`/detail_cul/${res.data.data.id}?status=1`);
                }
              })
              .catch(err => {
                this.loading.hide();
                this.ispost = false;
              });
          }
        } else {
          let checkGoodsType = ''
          await this.axios.post('common/encrypt',{goods_uuid: this.uuid,goods_derivative_uuid:this.uuid}).then((res)=>{
            checkGoodsType = res.data.data.data_encrypted
          })
          this.axios
            .get(
              `goods/checkGoodsType?data=${checkGoodsType}`
            )
            .then(async (res) => {
              if (res.data.msg != "goods") {
                this.axios
                  .get("/goodsder/info?uuid=" + this.uuid)
                  .then(res => {
                    if (res.data.code == -1) {
                      this.loading.hide();
                      this.ispost = false;
                      this.prpText = this.$t("main.soryiree");
                      this.showPrpPus = true;
                    } else {
                      this.loading.hide();
                      this.ispost = false;
                      this.$router.push(`/detail_cul/${res.data.data.id}?status=1`);
                    }
                  })
                  .catch(err => {
                    this.loading.hide();
                    this.ispost = false;
                  });
              } else {
                let newdata = ''
                await this.axios.post('common/encrypt',{uuid:this.uuid}).then((res)=>{
                  newdata = res.data.data.data_encrypted
                })
                this.axios
                  .get("/goods/info?data=" + newdata)
                  .then(res => {
                    if (res.data.code == -1) {
                      this.loading.hide();
                      this.ispost = false;
                      // weui.alert(this.$t('main.soryiree'), {
                      //   buttons: [
                      //     {
                      //       label: "我知道了",
                      //       type: "primary",
                      //     },
                      //   ],
                      // });

                      this.prpText = this.$t("main.soryiree");
                      this.showPrpPus = true;
                    } else {
                      this.loading.hide();
                      this.ispost = false;
                      this.$router.push(`/detail/${res.data.data.id}?status=1`);
                    }
                  })
                  .catch(err => {
                    this.loading.hide();
                    this.ispost = false;
                  });
              }
            });
        }
      } else {
        // weui.alert(this.$t('main.pensearchctx'), {
        //   buttons: [
        //     {
        //       label: "我知道了",
        //       type: "primary",
        //     },
        //   ],
        // });
        this.prpText = this.$t("main.pensearchctx");
        this.showPrpPus = true;
      }
    }
  }
};
</script>
<style scoped>
.or-box {
  font-size: 0.12rem;
  position: absolute;
  top: 0.57rem;
  left: 50%;
  transform: translateX(-50%);
}
.flex {
  display: flex;
}

.cirSvg {
  width: 0.175rem;
  height: 0.175rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #409eff; */
  border-radius: 50%;
  margin: 0 0.06rem;
  margin-left: 0.1rem;
  margin-right: 0.13rem;
}
.cirSvg > img {
  width: 0.175rem;
  object-fit: contain;
}
.cirSvg svg {
  width: 0.14rem;
  height: 0.14rem;
}

.button_scan {
  margin-bottom: 0.32rem;
  cursor: pointer;
  background: #fcdf91;
  height: 0.5rem;
  align-items: center;
  color: #fff;
  border-radius: 0.1rem;
}

.button_scan > div:nth-child(2) {
  font-size: 0.15rem;
  width: 0.92rem;
}

.button_scan > div:nth-child(3) {
  flex: 1;
  display: flex;
  /* justify-content: center; */
  font-size: 0.12rem;
}

.input_scan {
  height: 0.5rem;
  background: #fff;
  align-items: center;
  border-radius: 0.05rem;
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4); */
  background: #eeeeee;
  box-sizing: border-box;
  padding-right: 0.16rem;
  position: relative;
}

.input_s {
  margin: 0 0.1rem;
  display: flex;
  align-items: center;
  margin-right: 0.13rem;
}
.input_s > img {
  width: 0.17rem;
  object-fit: contain;
}
.input_s svg {
  width: 0.24rem;
  height: 0.24rem;
}

.input_scan > div:nth-child(2) {
  width: 1.7rem;
  text-align: left;
  font-size: 0.14rem;
}

.input_scan > input {
  font-size: 0.12rem;
}

.sou {
  justify-content: center;

  margin: 0 auto;
  margin-top: 0.3rem;
  width: 3.2rem;
  height: 0.48rem;
  background: url(../assets/img/view-more.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.16rem;
}

.cx-box {
  background: rgba(255, 255, 255, 0.6);

  padding: 0.2rem 0.15rem;
  border-radius: 0.1rem;
  margin-top: 0.175rem;
}
.zf-jc {
  font-size: 0.12rem;
  position: absolute;
  top: 0.52rem;
  right: 0;
}
.pop-pus {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.pop-pus .bg-img {
  width: 70%;
  height: 1.5rem;
  background: url(../assets/img/no-data-prompt.png) no-repeat center;
  background-size: 100% 100%;
  position: relative;
}
.pop-pus .title-wrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0.15rem;
}
.pop-pus .title-prompt {
  color: white;
  font-size: 0.15rem;
  width: 1.8rem;
  text-align: center;
}

.pop-pus .sure-logo {
  position: absolute;
  bottom: 0;
  margin-bottom: 0.25rem;
  width: 100%;
  padding: 0 0.3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.pop-pus .sure-img {
  width: 70%;
}
.sure_div {
  width: 1.4rem;
  height: 0.38rem;
  background: #f8d9ae;
  border-radius: 0.03rem;
  color: #266477;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
