<template>
  <div class="st-box">
    <div
        class="flex button_scan"
        @click="scan"
      >
        <div class="cirSvg">
          <img
            src="../assets/img/sys_r.png"
            alt=""
          />
        </div>
        <div style="color: #171717">{{$t('main.scan')}}</div>
        <div style="color: #5f5f5f">{{$t('main.scanM')}}</div>
      </div>
    <div class="search">
      <img src="../assets/img/search-user.png" alt="">
      <input type="text" class="search-st" :maxlength="15" :placeholder="$t('main.penofmast')" v-model="tcName" @keyup.enter="showTeacher" />
    </div>
    <div class="search-btn">
      <!-- <img @click="showTeacher($event)" src="../assets/img/search-button.png" alt="" /> -->
      <div @click="showTeacher($event)">{{ $t('main.search') }}</div>
    </div>
    <div class="recommend" v-if="tsList.length">
      <div class="text-seacher" >{{$t('main.queryrecord')}}</div>
      <img class="delete-icon" @click="deleteList" src="../assets/img/delete-icon-zm.png" alt="">
      <div class="select-btn">
        <div class="small-btn" v-for="(item,index) in tsList" :key="index" @click="btnSeatch(item)">{{item}}</div>
      </div>
    </div>
    <div class="pop-pus" v-show="showPrpPus">
      <div class="bg-img" >
        <div class="title-wrap">
          <div class="title-prompt">{{ prpText }}</div>
        </div>
        <div class="sure-logo">
          <div class="sure_div" @click="concatShow">
            {{ $t('main.comfirm') }}
          </div>
          <!-- <img class="sure-img" src="../assets/img/no-data-sure.png"   alt=""> -->
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
          tcName:"",
          showPrpPus:false,
          tsList:[
          ],
          prpText:"",
        }
    },
    mounted(){
      // this.axios.post(`/artist/getRecommendList`).then((res)=>{
      //   console.log(res)
      //   this.tsList = res.data.data.list ? res.data.data.list : [];
      // })
      this.tsList = JSON.parse(localStorage.getItem("tcName")) ? JSON.parse(localStorage.getItem("tcName")) : []
      

    },
    methods:{
      
      showTeacher(e){
        e.target.blur()
        if(!this.tcName.length){
          this.prpText = this.$t('main.penameofyouwant')
          this.showPrpPus = true
          return
        }
        if(this.tcName.length < 2){
          this.prpText = this.$t('main.petfullmname')
          this.showPrpPus = true
          return
        }
        if(this.tsList.indexOf(this.tcName) === -1){
          let lodTcName = JSON.parse(localStorage.getItem("tcName")) ? JSON.parse(localStorage.getItem("tcName")) : []
          lodTcName.unshift(this.tcName)
          if(lodTcName.length > 10){
            lodTcName.pop()
          }
          localStorage.setItem("tcName",JSON.stringify(lodTcName))
          this.tsList =lodTcName
        }
        
        
        this.$router.push({
          path:'/detailTeacher',
          query:{
            tcName:this.tcName
          }
        })
      },
      concatShow(){
        this.showPrpPus = false
      },
      btnSeatch(tcName){
        this.tcName = tcName
        this.$router.push({
          path:'/detailTeacher',
          query:{
            tcName
          }
        })
      },
      deleteList(){
        //  weui.alert('确定要清除查询记录吗？', {
				// 		buttons: [{
				// 			label: this.$t('main.cancel'),
				// 			type: 'primary',
				// 			onClick: () => {
								
				// 			}
				// 		}, {
				// 			label: this.$t('main.determine'),
				// 			onClick: () => {
        //         localStorage.removeItem("tcName")
        //         this.tsList=[]
				// 			}
				// 		}]
				// 	}) 
            localStorage.removeItem("tcName")
            this.tsList=[]
      },
      scan() {
        if(!this.$isWeiXin()){
          weui.alert('请在微信浏览器打开')
          return
        }
      wx.scanQRCode({
        needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        // success: (res) => {
        // 	var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果

        // 	let url = result.split('/detail/')
        // 	this.$router.push('/detail/' + url[1])
        // }
      });
    },
    }
}
</script>

<style scoped>
.st-box{
  background: rgba(255, 255, 255, 0.6);
  margin-top: 0.17rem;
  border-radius: 0.1rem;
  padding: 0.15rem;
}
.flex {
  display: flex;
}

.button_scan {
  margin-top: 0.05rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  background: #FCDF91;
  height: 0.5rem;
  align-items: center;
  color: #fff;
  border-radius: 0.1rem;
}
.button_scan > div:nth-child(2) {
  font-size: 0.15rem;
  width: 0.92rem;
}

.button_scan > div:nth-child(3) {
  flex: 1;
  display: flex;
  margin-top: 0.02rem;
  /* justify-content: center; */
  font-size: 0.12rem;
}
.cirSvg {
  width: 0.175rem;
  height: 0.175rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #409eff; */
  border-radius: 50%;
  margin: 0 0.06rem;
  margin-left: 0.1rem;
  margin-right: 0.13rem;
}
.cirSvg > img {
  width: 0.175rem;
  object-fit: contain;
}
.cirSvg svg {
  width: 0.14rem;
  height: 0.14rem;
}
.search{
  width: 100%;
  background-color: white;
  margin: 0.05rem 0;
  border-radius: 0.05rem;
  position: relative;
  height: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.search img{
  width: 0.2rem;
  margin-top: 0.01rem;
  position: absolute;
  left: 0.15rem;
  top: 0.15rem;
}
.search .search-st{
  margin-left: 0.5rem;
  border: none;
  font-size: 0.14rem;
}
.search .search-st::placeholder{
  color: rgb(159,159,159);
  font-size: 0.14rem;
}
.search-btn{
  margin: 0.2rem 0 0.1rem;
}
.search-btn img{
  width: 100%;
}
.search-btn div{
  width: 100%;
  height: 0.486rem;
  background: url(../assets/img/view-more.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.16rem;
}
.recommend{
  position: relative;
}
.text-seacher{
  color: rgb(131,115,112);
  margin-bottom: 0.1rem;
  font-size: 0.13rem;
}
.delete-icon{
  width: 0.15rem;
  height: 0.15rem;
  position: absolute;
  top: 0;
  right: 0.08rem;
}
.select-btn{
  display: flex;
  flex-wrap: wrap;
}
.select-btn .small-btn{
  padding: 0.03rem 0.14rem;
  border: 0.01rem solid white;
  border-radius: 0.15rem;
  color: rgb(70, 70, 70);
  margin-right: 0.08rem;
  margin-bottom: 0.1rem;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 0.13rem;
}
.pop-pus{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.pop-pus .bg-img{
  width: 70%;
  height: 1.5rem;
  background: url(../assets/img/no-data-prompt.png) no-repeat center;
  background-size: 100% 100%;
  position: relative;
}
.pop-pus .title-wrap{
  position: absolute;
  top: 0;
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0.15rem;

}
.pop-pus .title-prompt{
  color: white;
  font-size: 0.15rem;
  width: 1.8rem;
  text-align: center;
}

.pop-pus .sure-logo{
  position: absolute;
  bottom: 0;
  margin-bottom: 0.25rem;
  width: 100%;
  padding: 0 0.3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.pop-pus .sure-img{
  width: 70%;
}
.pop-pus .sure_div{
  width: 1.4rem;
  height: 0.38rem;
  background: #f8d9ae;
  border-radius: 0.03rem;
  color: #266477;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>